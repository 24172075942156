import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardText, Row, Col, Badge, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getHistoryOfTransactions } from '../api/transaction';
import EdittransactionModal from '../pages/Child/EdittransactionModal';
import { connect } from 'react-redux';

const TransactionCard = ({ transaction, childDetail, refresh, authUser }) => {
  const [modal, setModal] = useState(false);
  const [history, setHistory] = useState([]);
  const [editModal, setEditModal] = useState(false)
  const toggleModal = () => {
    callHistory();
    setModal(!modal);
  };

  const tableStyle = {
    // border: '0.5px solid gray',
    borderCollapse: 'collapse',
    width: '100%',
  };

  const headerStyle = {
    backgroundColor: '#3AA6AE',
    color: 'white',
  };

  const rowStyleEven = {
    backgroundColor: 'white',
  };

  const rowStyleOdd = {
    backgroundColor: '#EAF6F6',
  };


  const cellStyle1 = {
    border: 'none',
    textAlign: 'right'
  };


  const cellStyle2 = {
    border: 'none',
    // textAlign:'right'
  };

  const {
    date,
    dueAmount,
    totalAmountToPay,
    paidAmount,
    batchId,
    isSessionBatch,
    registeredSessions,
    invoiceDetail,
    madeBy,
    discountAmount,
    discountType,
    address
  } = transaction;

  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  let formattedUserType;

  if (madeBy) {
    formattedUserType = madeBy?.user_type ? madeBy?.user_type?.replace('_', ' ')?.replace(/\b\w/g, c => c.toUpperCase()) : '';
  }

  const downloadInvoice = () => {
    const input = document.getElementById('invoice');
    const scale = 2
    html2canvas(input, { scale })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`invoice_${batchId.name}.pdf`);
      });
  };

  const callHistory = async () => {
    const response = await getHistoryOfTransactions({ child_id: childDetail?._id, batch_id: batchId?._id });
    setHistory(response?.data);
  };

  return (
    <div>

      <EdittransactionModal
        modalOpenState={editModal}
        closeModalHandler={() => setEditModal(prev => !prev)}
        data={transaction}
        childId={childDetail?._id}
        childinfo={childDetail}
        refresh={refresh}
      />
      <Card className="my-3">
        <CardBody>
          <Row className='mb-1 ml-2 d-flex justify-content-between align-items-center'>

            <Col className='text-left' md={6}>
              <Row>
                <strong><i className="bi bi-file-earmark-text"></i> Invoice ID:</strong> {transaction?.InvoiceID}
              </Row>
              <Row>
                <strong><i className="bi bi-calendar-event"></i> Date: </strong> {" " + formattedDate} {formattedTime}
              </Row>

            </Col>
            <Col md={6}>
              <Row className='justify-content-end'>
                <Col
                  className='text-right'>
                  <Button size="sm"
                    color="primary"
                    className=" waves-effect waves-light mr-2 mb-2"
                    onClick={toggleModal}>View Invoice</Button>
                </Col>
                {transaction?.latest && authUser.user_type === "super_admin" ? <Button size="sm" color="warning" className="waves-effect waves-light mr-2 mb-2" onClick={() => {
                  setEditModal((prev) => !prev)
                }}>
                  <i className="fas fa-edit mr-2"></i>
                  Edit
                </Button> : null}
              </Row>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='text-left'>
            </Col>
          </Row>

          <CardText>
            <Row >
              <Col md='6' sm="12" className="form-control my-1 mx-3" style={{ backgroundColor: isSessionBatch ? "pink" : "greenyellow" }}>
                <strong> Batch Type:</strong> {isSessionBatch ? "Session Batch" : "Normal Batch"}
              </Col>
              <Col md='5' className="form-control my-1 mx-3" sm="12">
                <strong> Batch Name:</strong> {batchId.name}
              </Col>

              {transaction?.isSessionBatch ? (
                <Col md='7' className="form-control my-1 mx-3" sm="12">
                  <strong><i className="bi bi-currency-rupee"></i> Total Fees (Reg Session x Per Session Fee):</strong> Rs. {registeredSessions * transaction?.perSessionFee}
                </Col>

              ) : (
                <Col md='6' className="form-control my-1 mx-3" sm="12">
                  <strong><i className="bi bi-currency-rupee"></i> Total Fees :</strong> Rs. {totalAmountToPay}
                </Col>

              )}
              <Col md='5' className="form-control my-1 mx-3" sm="12">
                <strong><i className="bi bi-exclamation-circle"></i> Due Amount:</strong> ₹{dueAmount}
              </Col>
              {transaction?.isSessionBatch && <Col md='6' sm="12" className="form-control my-1 mx-3">
                <strong><i className="bi bi-calendar-range"></i> Per Session Fees:</strong> Rs. {transaction?.perSessionFee}
              </Col>}
              {registeredSessions && (
                <Col md='5' className="form-control my-1 mx-3" sm="12">
                  <strong><i className="bi bi-list-check"></i> Registered Sessions:</strong> {registeredSessions}
                </Col>
              )}
            </Row>
            {invoiceDetail && invoiceDetail.length > 0 && (
              <Row className='my-3'>
                <Col>
                  <Table bordered style={{ border: "1px solid" }}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Rate</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetail.map((item, index) => (
                        <tr key={index}>
                          <td >{item.description}</td>
                          <td>₹{item.rate}</td>
                          <td>{item.quantity}</td>
                          <td>₹{item.price}</td>
                        </tr>
                      ))}
                      <tr >
                        <td ></td>
                        <td></td>
                        <td style={{ borderBottom: "1px solid #3AA6AE" }} >Amount Paid Before Discount</td>
                        <td style={{ borderBottom: "1px solid #3AA6AE" }}>₹{paidAmount}</td>
                      </tr>
                      <tr >
                        <td ></td>
                        <td></td>
                        <td style={{ borderBottom: "1px solid #3AA6AE" }} >{discountType ? discountType : "Promotional"} Discount</td>
                        <td style={{ borderBottom: "1px solid #3AA6AE" }}>-₹{discountAmount ? discountAmount : 0}</td>
                      </tr>
                      <tr >
                        <td ></td>
                        <td></td>
                        <td>Final Amount Paid</td>
                        <td>₹{paidAmount - (discountAmount ? discountAmount : 0)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {/* <Row>
              <Col md='6'></Col>
              <Col md='6' className="form-control text-center">
                <strong><i className="bi bi-currency-rupee"></i> Amount Paid:</strong> ₹{paidAmount}
              </Col>
            </Row> */}
            {madeBy ? (
              <Row className="mt-3">
                <Col>
                  <Card className="border-1">
                    <CardBody>
                      <h6 className="mb-3">Transaction Made By:</h6>
                      <Table bordered>
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Designation</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td><strong>Name:</strong></td> */}
                            <td>{madeBy.name}</td>
                            {/* <td><strong>Email:</strong></td> */}
                            <td>{madeBy.email}</td>
                            {madeBy.user_type && (

                              <td>{formattedUserType}</td>
                            )}

                          </tr>

                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </CardText>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Invoice</ModalHeader>
        <ModalBody>
          <div id="invoice">
            <div style={{ backgroundColor: '#3AA6AE', height: '20px', width: '100%' }}></div>
            <div style={{ width: '100%', margin: '0 auto', padding: '20px', border: '1px outset #ccc' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '180px', height: '180px' }}>
                    <img src='/rr_ico.png' alt="Raising Right Logo" style={{ height: '100%', width: '100%' }} />
                  </div>
                  <div style={{ display: 'inline-block', paddingTop: '20px', paddingRight: '10px' }}>
                    <h2>Raising Right</h2>
                    <i style={{ fontSize: 14 }}>{address ? address : "5, Kamla Nagar, M.G. Road, Kandivali West"}<br />Mumbai, MH 400067</i>
                    <p style={{ fontSize: 14 }}>8355953059<br />raisingright.education<br />raisingrightpreschool@gmail.com</p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '20 px', textAlign: "left" }}>
                    <span>
                      <p style={{ fontSize: "16px", textAlign: 'end', fontWeight: 'bold', margin: 0, padding: 0 }}>INVOICE</p>
                      <p style={{ fontSize: "13px", textAlign: 'end' }}>{transaction?.InvoiceID}</p>
                    </span>
                    <span>
                      <p style={{ fontSize: "16px", textAlign: 'end', fontWeight: 'bold', margin: 0, padding: 0 }}>DATE</p>
                      <p style={{ fontSize: "13px", textAlign: 'end' }}>{formattedDate} {formattedTime}</p>
                      {/* <p style={{ fontSize: "11px", textAlign: 'end' }}></p> */}
                    </span>
                    <span>
                      <p style={{ fontSize: "16px", textAlign: 'end', fontWeight: 'bold', margin: 0, padding: 0 }}>TOTAL FEES</p>
                      <p style={{ fontSize: "13px", textAlign: 'end' }}>INR ₹{isSessionBatch ? registeredSessions * transaction?.perSessionFee : totalAmountToPay}</p>
                    </span>
                    <span>
                      <p style={{ fontSize: "16px", textAlign: 'end', fontWeight: 'bold', margin: 0, padding: 0 }}>BALANCE DUE</p>
                      <p style={{ fontSize: "13px", textAlign: 'end' }}>INR ₹{dueAmount}</p>
                    </span>
                    {/* {transaction?.isSessionBatch && <span>
                      <p style={{ fontSize: "16px", textAlign: 'end', fontWeight: 'bold', margin: 0, padding: 0 }}>No. of Registered Sessions</p>
                      <p style={{ fontSize: "14px", textAlign: 'end', color: '#3AA6AE', fontWeight: 'bold' }}>{transaction?.registeredSessions}</p>
                    </span>} */}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '10px 0px 5px 0px' }}>
                <div>
                  <p>BILL TO</p>
                  <h4>{childDetail.name}</h4>
                  <i>{childDetail.address}</i>
                  <p>{childDetail.phone_number}<br />{childDetail.email_address}</p>
                </div>
              </div>
              <div>
                <Table style={tableStyle}>
                  <thead>
                    <tr style={headerStyle}>
                      <th style={cellStyle2}>DESCRIPTION</th>
                      <th style={cellStyle1}>RATE</th>
                      <th style={cellStyle1}>QTY</th>
                      <th style={cellStyle1}>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetail.map((item, index) => (
                      <tr key={index} style={index % 2 === 0 ? rowStyleEven : rowStyleOdd}>
                        <td style={cellStyle2}>{item.description}</td>
                        <td style={cellStyle1}>₹{item.rate}</td>
                        <td style={cellStyle1}>{item.quantity}</td>
                        <td style={cellStyle1}>₹{item.price}</td>
                      </tr>
                    ))}
                    <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}>Before Discount Amount</td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}>{paidAmount}</td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}>{discountType ? discountType : "Promotional"} Discount</td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}>-₹{discountAmount ? discountAmount : 0}</td>
                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}><b>Total Paid</b></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}>₹{paidAmount - (discountAmount ? discountAmount : 0)}</td>
                    </tr>
                    {/* <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={cellStyle1}></td>
                      <td style={cellStyle1}></td>
                    </tr> */}
                    <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}><b>PAST PAID AMOUNT</b></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right' }}> ₹{transaction?.isSessionBatch ? ((transaction?.perSessionFee * registeredSessions) - paidAmount - dueAmount) : (totalAmountToPay - paidAmount - dueAmount)}</td>

                    </tr>
                    <tr>
                      <td style={cellStyle1}>
                      </td>
                      <td style={cellStyle1}></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right', backgroundColor: "#CDE9EA" }}><b>Balance Due</b></td>
                      <td style={{ borderTop: "2px solid #CDE9EA ", textAlign: 'right', backgroundColor: "#CDE9EA" }}>INR ₹{dueAmount}</td>
                    </tr>


                  </tbody>
                </Table>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '20px' }}>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', textAlign: 'right', marginBottom: '10px' }}>
                  <strong>TOTAL:</strong> ₹{totalAmountToPay}
                </div>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', textAlign: 'right', marginBottom: '10px' }}>
                  <strong>PAST PAYMENT:</strong>₹{totalAmountToPay - paidAmount - dueAmount}<br />
                </div>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', textAlign: 'right', marginBottom: '10px' }}>
                  <strong>PAID:</strong>₹{paidAmount}<br />
                </div>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <strong>BALANCE DUE:</strong>
                  <p style={{ fontWeight: 'bold', fontSize: 15 }}> INR ₹{dueAmount}</p>
                </div>
              </div> */}
            </div>
            <h4 style={{ textAlign: 'center', color: "grey", marginTop: 20 }}>Transaction History</h4>
            <div style={{ display: "flex", justifyContent: "center", margin: "0px 20px" }}>
              {history.length <= 0 ? <p>No previous transactions!</p> : null}
              {history.length > 0 &&
                <table style={{ borderStyle: 'outset', borderWidth: "0.1px", borderColor: "#078691", width: "100%", margin: "15px 35px" }}>
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold", textAlign: "center" }}>Transaction ID</th>
                      <th style={{ fontWeight: "bold", textAlign: "center" }}>Date</th>
                      <th style={{ fontWeight: "bold", textAlign: "center" }}>Amount Paid</th>
                      <th style={{ fontWeight: "bold", textAlign: "center" }}>Due Amount</th>
                      {history.some(item => item?.isSessionBatch) && <th style={{ fontWeight: "bold", textAlign: 'center' }}>Registered Sessions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((item, index) => (
                      <tr key={index} style={{ borderStyle: 'solid', borderWidth: "0.1px", padding: "5px", borderColor: "#078691" }}>
                        <td style={{ textAlign: 'center' }}><strong>{item?.InvoiceID}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{formatDate(item?.date)}</strong></td>
                        <td style={{ textAlign: 'center' }}>INR {item?.paidAmount}</td>
                        <td style={{ textAlign: 'center' }}>INR {item?.dueAmount}</td>
                        {item?.isSessionBatch && <td style={{ textAlign: 'center' }}>{item?.registeredSessions}</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={downloadInvoice}>Download Invoice</Button>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(TransactionCard);

/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Button,
  Badge,
  NavLink,
  NavItem,
  Nav,
  Collapse,
  CardFooter,
  Form
} from 'reactstrap';

import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';
import {
  getBatchActivityList,
  getBatchChildrenList,
  getBatchInfo
} from '../../api/batch';
import AddChildModal from './AddChildModal';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ADD_EXECUTION,
  ASSIGN_ACTIVITIES,
  ASSIGN_CHILDREN,
  CREATE_BATCH_IMAGE,
  CREATE_FORM_RESPONSE,
  CREATE_NOTICE,
  DELETE_NOTICE,
  UPDATE_NOTICE,
  VIEW_BATCH_IMAGE,
  VIEW_NOTICES
} from '../Utility/PermissionKeys';
import { validatePermissionKey } from '../../helpers/permission';
import PageDataLoader from '../Utility/PageDataLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, useHistory } from 'react-router-dom';
import Paginator from '../Utility/Paginator';
import BatchActivityFilterModal from './BatchActivityFilterModal';
import AddNoticeModal from './AddNoticeModal';
import { getNoticeInfo, getNoticeList } from '../../api/notice';
import UpdateNoticeModal from './UpdateNoticeModal';
import DeleteNoticeModal from './DeleteNoticeModal';
import { getBatchImage } from '../../api/batchImage';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import AddBatchImageModal from './AddBatchImageModal';
import ThumbnailGallery from './ThumbnailGallery';
import CloneBatchModal from './CloneBatchModal';
import DeleteBatchActivitiesModal from './DeleteBatchActivitiesModal';
import {
  getFormResponse,
  submitFormResponse,
  updateFormResponse
} from '../../api/form';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { crudSectionResponse, getSectionResponse } from '../../api/section';

const ViewSingleBatch = (props) => {
  const history = useHistory();
  const [addChildModalOpen, setAddChildModalOpen] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'Batches', link: '/batches' },
    { title: 'View Batch', link: '#' }
  ]);
  const [batchImageDate, setBatchImageDate] = useState(null);
  const [batchImages, setBatchImages] = useState([]);
  const [batchImagesLoading, setBatchImagesLoading] = useState(false);
  const [batchImageModal, setBatchImageModal] = useState(false);
  const [batchInfo, setBatchInfo] = useState({});
  const [batchInfoLoading, setBatchInfoLoading] = useState(true);
  const [childInfoLoading, setChildInfoLoading] = useState(true);
  const [activityInfoLoading, setActivityInfoLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [activityError, setActivityError] = useState('');
  const [filterModal, setFilterModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    message: '',
    show_alert: false
  });
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [cloneModal, setCloneModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [childrenData, setChildrenData] = useState([]);
  const [activeTab, setActiveTab] = useState('basic_detail');
  const [addNoticeModalOpen, setAddNoticeModalOpen] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({});
  const [noticeInfoLoading, setNoticeInfoLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [updateNoticeModalOpen, setUpdateNoticeModalOpen] = useState(false);
  const [deleteNoticeModalOpen, setDeleteNoticeModalOpen] = useState(false);
  const [noticeDetail, setNoticeDetail] = useState({});
  const [noticeDetailLoading, setNoticeDetailLoading] = useState(false);
  const [sectionToggle, setSectionToggle] = useState(false)
  const [noticeId, setNoticeId] = useState({
    noticeId: '',
    type: ''
  });
  const [noticePagination, setNoticePagination] = useState({
    currentPage: 1,
    pageLimit: 5,
    totalEntries: 0,
    totalPages: 0
  });
  const [open, setOpen] = useState(0);

  const [childPagination, setChildPagination] = useState({
    currentPage: 1,
    pageLimit: 5,
    totalEntries: 0,
    totalPages: 0
  });

  const [activityPagination, setActivityPagination] = useState({
    currentPage: 1,
    pageLimit: 5,
    totalEntries: 0,
    totalPages: 0
  });
  const [deleteActivitiesModal, setDeleteActivitiesModal] = useState(false);
  const [formResponse, setFormResponse] = useState(undefined);
  const [sectionResponse, setSectionResponse] = useState(undefined)
  const [sectionResponseLoading, setSectionResponseLoading] = useState(false)

  const [toggleForm, setToggleForm] = useState(null);
  const [sectionWeightage, setSectionWeightage] = useState({
    totalWeightage: 0,
    childWeightage: 0
  })
  const COLORS = ['#ED8F8F', '#3AA6AE'];
  const [weightageData, setWeightageData] = useState([])


  const setToggeform = async (index, child_id) => {
    console.log('set Togge Form', index)
    if (toggleForm === index) {
      setToggleForm(null);
    } else {
      setToggleForm(index);
      setSectionToggle(null)
      // setSectionLoading(true);

      // const response = await getFormResponse(
      //   child_id,
      //   props.match.params.batchId
      // );
      // if (response) {
      //   setFormResponse(response.data);
      //   // setup weightage here 
      //   
      //   setSectionLoading(false);
      // } else {
      //   setErrorAlert({
      //     message: 'Form not found',
      //     show_alert: true
      //   });
      //   setSectionLoading(false);
      // }
    }
  };


  const toggleSectionForm = async (index, child_id, section_id) => {
    setSectionToggle(index)
    setSectionLoading(true)
    const response = await getSectionResponse(child_id, props.match.params.batchId, section_id)
    if (response.error) {

      setErrorAlert({
        message: 'Error in getting section info',
        show_alert: true
      });
      setSectionLoading(false);
    }
    else {
      console.log(response,'response')
      setSectionResponse(response.response?.data[0])
      // response.data.section_responses.map((section) => {
      //   let sec_id = section._id

      // })

      let section = response.response?.data[0]
      let total_weightage = 0
      let child_weightage = 0
      console.log(section,'section')

      section.section_data.questions.map((question) => {
        question.option_list.map((opt) => {
          if (opt.is_correct) {
            total_weightage += opt.weightage || 1
          }
          if (question.answer && question.answer === opt._id) {
            child_weightage += opt.weightage || 1

          }

        })
      })
      let weightage_obj = {
        // section_id: sec_id,
        totalWeightage: total_weightage,
        childWeightage: child_weightage
      }
      setSectionWeightage(weightage_obj)
      setWeightageData([{ name: 'Childs weightage', value: child_weightage },
      { name: 'Remaining weightage', value: total_weightage - child_weightage }
      ])
      setSectionLoading(false)
    }

  }



  function openAddChildModal() {
    setAddChildModalOpen(true);
  }

  function closeAddChildModal() {
    setAddChildModalOpen(false);
  }

  function openFilterModal() {
    setFilterModal(true);
  }

  function closeFilterModal() {
    setFilterModal(false);
  }

  function openDeleteActivitiesModal() {
    setDeleteActivitiesModal(true);
  }

  function closeDeleteActivitiesModal() {
    setDeleteActivitiesModal(false);
  }

  function openCloneModal() {
    setCloneModal(true);
  }

  function closeCloneModal() {
    setCloneModal(false);
  }

  function openAddNoticeModal() {
    setAddNoticeModalOpen(true);
  }

  function closeAddNoticeModal() {
    setAddNoticeModalOpen(false);
  }
  function openUpdateNoticeModal(id) {
    fetchNoticeDetail(id);
    setUpdateNoticeModalOpen(true);
  }

  function closeUpdateNoticeModal() {
    setUpdateNoticeModalOpen(false);
  }
  function openDeleteNoticeModal(id, type) {
    setNoticeId({
      noticeId: id,
      type
    });
    setDeleteNoticeModalOpen(true);
  }

  function closeDeleteNoticeModal() {
    setDeleteNoticeModalOpen(false);
  }

  function openBatchImageModal(id) {
    setBatchImageModal(true);
  }

  function closeBatchImageModal() {
    setBatchImageModal(false);
  }

  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    fetchBatchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, listElem, linkElem]);

  useEffect(() => {
    if (batchInfo.start_date) {
      setStartTime(batchInfo.start_time);
      setStartDate(batchInfo.start_date);
      setEndDate(batchInfo.end_date);
      fetchActivityInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchInfo.start_date]);

  const handleChildPagination = (page) => {
    fetchChildrenInfo(page, childPagination.pageLimit);
  };

  const handleNoticePagination = (page) => {
    fetchNoticeInfo(page);
  };

  const handleActivityPagination = (page) => {
    fetchActivityInfo(
      selectedDate,
      filterName,
      page,
      activityPagination.pageLimit
    );
  };

  async function fetchBatchInfo() {
    setBatchInfoLoading(true);
    const response = await getBatchInfo(props.match.params.batchId);
    if (response) {
      setBatchInfo(response);
      setChildrenData(response.children);
      setBatchInfoLoading(false);
    } else {
      setErrorAlert({
        message: 'Batch details not found',
        show_alert: true
      });
      setBatchInfoLoading(false);
    }
  }

  const fetchChildrenInfo = async (current_page = 1, page_limit = 5) => {
    setChildInfoLoading(true);
    const childrenArray = await getBatchChildrenList(
      props.match.params.batchId,
      current_page,
      page_limit
    );

    if (childrenArray) {
      setChildPagination({
        currentPage: childrenArray.metadata.page,
        pageLimit: childrenArray.metadata.limit,
        totalPages: childrenArray.metadata.total_pages,
        totalEntries: childrenArray.metadata.total_results
      });
      setBatchInfo({ ...batchInfo, children: childrenArray.data });
      setChildInfoLoading(false);
    }
  };

  const fetchActivityInfo = async (
    date = selectedDate,
    name = filterName,
    current_page = 1,
    page_limit = 5
  ) => {
    setActivityError('');
    setActivityInfoLoading(true);
    let from_date = moment(new Date())
      .subtract(15, 'days')
      .format('YYYY-MM-DD');
    let to_date = moment(new Date()).add(15, 'days').format('YYYY-MM-DD');

    const activityArray = await getBatchActivityList(
      props.match.params.batchId,
      date ? moment(new Date(date)).format('YYYY-MM-DD') : null,
      name,
      current_page,
      page_limit,
      from_date,
      to_date
    );

    if (activityArray) {
      setActivityPagination({
        currentPage: activityArray.metadata.page,
        pageLimit: activityArray.metadata.limit,
        totalPages: activityArray.metadata.total_pages,
        totalEntries: activityArray.metadata.total_results
      });

      setActivities(activityArray.data);
      setActivityInfoLoading(false);
    } else {
      setActivityInfoLoading(false);
      setActivityError(
        'Given date does not lie between batch start and end date'
      );
    }
  };

  const fetchBatchData = async () => {
    await fetchBatchInfo();
    await fetchChildrenInfo();
    await fetchActivityInfo();
  };

  const updateFilterCount = (name, date) => {
    let count = 0;
    if (name) count += 1;
    if (date) count += 1;
    setCurrentFilterCount(count);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleNotice = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  async function fetchNoticeDetail(noticeId) {
    setNoticeDetailLoading(true);
    const response = await getNoticeInfo(noticeId);
    if (response) {
      setNoticeDetail(response);
      setNoticeDetailLoading(false);
    }
  }

  const fetchNoticeInfo = async (current_page = 1, page_limit = 5) => {
    setNoticeInfoLoading(true);
    const response = await getNoticeList(current_page, page_limit, {
      batch: props.match.params.batchId
    });

    if (response) {
      setNoticePagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setNoticeInfo(response.data);
      setNoticeInfoLoading(false);
    }
  };

  async function fetchBatchImages(date) {
    setBatchImagesLoading(true);
    const response = await getBatchImage(props.match.params.batchId, date);
    if (response) {
      setBatchImages(response.images);
    } else {
      setBatchImages([]);
    }
    setBatchImagesLoading(false);
  }

  function handleOptionChange(e, questionId, option) {
    setSectionResponse((data) => {
      const updatedQuestions = data.section_data.questions.map(
        (question) => {
          if (question._id === questionId) {
            return { ...question, answer: option._id };
          }
          else {
            return { ...question }
          }

        }

      );

      return {
        ...data,
        // "section_data.questions": updatedQuestions
        section_data: {
          ...data.section_data,
          questions: updatedQuestions
        }
      };
    })

  }

  const handleformsubmit = async (index, childId, sectionId) => {
    setSectionResponseLoading(true)
    let question_response = []
    sectionResponse.section_data.questions.map((question) => {
      let correct = question.option_list.filter((k) => k.is_correct === true)
      question_response.push({ id: question._id, answer: question.answer, correct_answer: correct[0]._id })
    })
    // if (formResponse.found === true) {
    //   setToggeform(toggleForm);
    const response = await crudSectionResponse(childId, props.match.params.batchId, sectionId, question_response);
    if (response.error) {
      setErrorAlert({
        message: response.error,
        show_alert: true
      });
    }
    else {
      // setToggeform(toggleForm);
      setSuccessAlert({
        message: response.response.message,
        show_alert: true
      });
      toggleSectionForm(index, childId, sectionId)
    }
    setSectionResponseLoading(false)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {errorAlert.show_alert && (
          <SweetAlert
            danger
            title=""
            onConfirm={() => history.push('/batches')}
          >
            {errorAlert.message}
          </SweetAlert>
        )}
        {successAlert.show_alert && (
          <SweetAlert
            success
            title=""
            onConfirm={() => {
              setSuccessAlert({ message: '', show_alert: false });
              fetchBatchInfo();
            }}
          >
            {successAlert.message}
          </SweetAlert>
        )}
        {batchInfoLoading ? (
          <div className="d-flex justify-content-center">
            <PageDataLoader />
          </div>
        ) : (
          <>
            {batchInfo && (
              <>
                <AddChildModal
                  modalOpenState={addChildModalOpen}
                  closeModalHandler={closeAddChildModal}
                  fetchNewData={fetchChildrenInfo}
                  batchId={props.match.params.batchId}
                  authUser={props.authUser}
                />
              </>
            )}
            {batchInfo && activities && (
              <>
                <AddBatchImageModal
                  modalOpenState={batchImageModal}
                  closeModalHandler={closeBatchImageModal}
                  fetchNewData={() => fetchBatchImages(batchImageDate)}
                  batch={props.match.params.batchId}
                  date={batchImageDate}
                />

                <AddNoticeModal
                  modalOpenState={addNoticeModalOpen}
                  closeModalHandler={closeAddNoticeModal}
                  fetchNewNoticeList={fetchNoticeInfo}
                  batch={props.match.params.batchId}
                />
                <UpdateNoticeModal
                  modalOpenState={updateNoticeModalOpen}
                  closeModalHandler={closeUpdateNoticeModal}
                  fetchNewNoticeList={fetchNoticeInfo}
                  batch={props.match.params.batchId}
                  noticeInfo={noticeDetail}
                  noticeInfoLoading={noticeDetailLoading}
                />
                {noticeId && (
                  <DeleteNoticeModal
                    modalOpenState={deleteNoticeModalOpen}
                    closeModalHandler={closeDeleteNoticeModal}
                    noticeId={noticeId.noticeId}
                    type={noticeId.type}
                    fetchNewData={fetchNoticeInfo}
                  />
                )}

                <CloneBatchModal
                  modalOpenState={cloneModal}
                  closeModalHandler={closeCloneModal}
                  batch={batchInfo}
                />

                <BatchActivityFilterModal
                  modalOpenState={filterModal}
                  closeModalHandler={closeFilterModal}
                  fetchNewData={fetchActivityInfo}
                  name={filterName}
                  setName={setFilterName}
                  date={selectedDate}
                  setDate={setSelectedDate}
                  startDate={startDate}
                  endDate={endDate}
                  updateFilterCount={updateFilterCount}
                  authUser={props.authUser}
                />
                <DeleteBatchActivitiesModal
                  modalOpenState={deleteActivitiesModal}
                  closeModalHandler={closeDeleteActivitiesModal}
                  fetchNewData={fetchActivityInfo}
                  batchId={props.match.params.batchId}
                />
              </>
            )}
            <Container fluid>
              <Breadcrumbs
                title={batchInfo.name}
                breadcrumbItems={breadcrumbItems}
              />
              <Row className="mt-2 mb-3">
                <Col>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 'basic_detail' && 'activeBatchTab'
                        }
                        onClick={() => {
                          toggle('basic_detail');
                        }}
                      >
                        Batch Details
                      </NavLink>
                    </NavItem>
                    {validatePermissionKey(props.authUser, VIEW_NOTICES) && (
                      <NavItem>
                        <NavLink
                          className={activeTab === 'notice' && 'activeBatchTab'}
                          onClick={() => {
                            toggle('notice');
                            fetchNoticeInfo();
                          }}
                        >
                          Notice
                        </NavLink>
                      </NavItem>
                    )}
                    {validatePermissionKey(
                      props.authUser,
                      VIEW_BATCH_IMAGE
                    ) && (
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === 'batch_image' && 'activeBatchTab'
                            }
                            onClick={() => {
                              toggle('batch_image');
                              let date =
                                new Date() >= new Date(batchInfo.start_date) &&
                                  new Date() <= new Date(batchInfo.end_date)
                                  ? new Date()
                                  : new Date(batchInfo.start_date);
                              date = moment(date).format('YYYY-MM-DD');
                              setBatchImageDate(date);
                              fetchBatchImages(date);
                            }}
                          >
                            Batch Image
                          </NavLink>
                        </NavItem>
                      )}
                    {validatePermissionKey(
                      props.authUser,
                      CREATE_FORM_RESPONSE
                    ) && (
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === 'form_response' && 'activeBatchTab'
                            }
                            onClick={() => {
                              toggle('form_response');
                            }}
                          >
                            Learner's Profile
                          </NavLink>
                        </NavItem>
                      )}
                  </Nav>
                </Col>
              </Row>
              {activeTab === 'basic_detail' ? (
                <>
                  <Row>
                    <Col>
                      <Badge
                        color="primary"
                        className="mr-2 mb-3"
                        style={{
                          padding: '10px 20px',
                          fontSize: '16px'
                        }}
                        pill
                      >
                        {new Date(batchInfo.start_date) > new Date()
                          ? 'Upcoming'
                          : new Date(batchInfo.end_date) < new Date()
                            ? 'Expired'
                            : 'Active'}{' '}
                        Batch
                      </Badge>
                    </Col>
                    {/* <Col className="d-flex justify-content-end align-items-center"></Col> */}
                    <Col className="d-flex justify-content-end align-items-center">
                      {props.authUser &&
                        props.authUser.user_type === 'super_admin' && (
                          <Button
                            size="sm"
                            color="warning"
                            className=" waves-effect waves-light mr-3"
                            onClick={openCloneModal}
                          >
                            <i className="ri-file-copy-line align-middle mr-2"></i>
                            <span>Clone Batch</span>
                          </Button>
                        )}
                      {validatePermissionKey(props.authUser, ADD_EXECUTION) && (
                        <Button
                          size="sm"
                          color="success"
                          className=" waves-effect waves-light"
                          onClick={() =>
                            history.push({
                              pathname: `/executions/create/${props.match.params.batchId}`,
                              state: {
                                batchStartDate: batchInfo.start_date,
                                batchEndDate: batchInfo.end_date,
                                batchName:batchInfo.name
                              }
                            })
                          }
                        >
                          <i className="ri-add-line align-middle mr-2"></i>
                          Add Batch Execution
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label>Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo?.name}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label>Offering</Label>
                                <Input
                                  name="offering"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo.offering?.name}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Start Date</Label>
                                <Input
                                  name="start_date"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo &&
                                    moment(
                                      new Date(batchInfo.start_date)
                                    ).format('DD-MM-YYYY')
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label>End Date</Label>
                                <Input
                                  name="end_date"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo &&
                                    moment(new Date(batchInfo.end_date)).format(
                                      'DD-MM-YYYY'
                                    )
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Time</Label>
                                <Input
                                  name="time"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo &&
                                    batchInfo.start_time +
                                    '-' +
                                    batchInfo.end_time
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label>Schedule</Label>
                                <Input
                                  name="schedule"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo?.schedule}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Primary Teacher</Label>
                                <Input
                                  name="primary_teacher"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo.primary_teacher?.name}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label>Secondary Teacher</Label>
                                <Input
                                  name="secondary_teacher"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo.secondary_teacher?.name
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Batch type</Label>
                                <Input
                                  name="secondary_teacher"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo.isSessionBatch ? "Session Batch" : "Normal Batch"
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            {batchInfo.isSessionBatch && <Col md="6">
                              <FormGroup>
                                <Label>No of sessions</Label>
                                <Input
                                  name="secondary_teacher"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo.totalSessions}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>}
                            <Col md="6">
                              <FormGroup>
                                <Label>{batchInfo.isSessionBatch ? "Session Fee" : "Batch Fee"}</Label>
                                <Input
                                  name="secondary_teacher"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    batchInfo.sessionFee
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            {batchInfo.isSessionBatch && (
                              <Col md="6">
                                <FormGroup>
                                  <Label>Total Fee</Label>
                                  <Input
                                    name="secondary_teacher"
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      batchInfo.totalFee
                                    }
                                    readOnly
                                  />
                                </FormGroup>
                              </Col>
                            )}

                            <Col md="12">
                              <FormGroup>
                                <Label>Summary</Label>
                                <Input
                                  name="summary"
                                  type="text"
                                  className="form-control"
                                  defaultValue={batchInfo?.summary}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup>
                                <Label>Detail</Label>
                                <Input
                                  name="detail"
                                  type="textarea"
                                  className="form-control"
                                  defaultValue={batchInfo?.detail}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={6} xs={12}>
                      <Card>
                        <CardBody>
                          <Row className="mt-2 mb-2">
                            <Col md="5">
                              <CardTitle tag="h5">Children </CardTitle>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                              {validatePermissionKey(
                                props.authUser,
                                ASSIGN_CHILDREN
                              ) &&
                                !batchInfo.isDeleted && (
                                  <Button
                                    size="sm"
                                    color="success"
                                    className=" waves-effect waves-light"
                                    onClick={openAddChildModal}
                                  >
                                    <i className="ri-add-line align-middle mr-2"></i>
                                    Assign Children
                                  </Button>
                                )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Col md="12">
                                  <Label className="col-form-label">
                                    Show Entries
                                  </Label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      fetchChildrenInfo(1, e.target.value);
                                      setChildPagination({
                                        ...childPagination,
                                        pageLimit: e.target.value
                                      });
                                    }}
                                    value={childPagination.pageLimit}
                                    disabled={childInfoLoading}
                                  >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={100}>100</option>
                                  </select>
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                          {childInfoLoading ? (
                            <div className="d-flex justify-content-center">
                              <PageDataLoader />
                            </div>
                          ) : (
                            <>
                              {batchInfo.children.length > 0 ? (
                                <div className="table-rep-plugin">
                                  <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                  >
                                    <Table
                                      id="tech-companies-1"
                                      striped
                                      bordered
                                      responsive
                                    >
                                      <thead>
                                        <tr>
                                          <th>SL. No</th>
                                          <th>Roll no</th>
                                          <th>Name</th>
                                          <th>Gender</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {batchInfo.children?.map(
                                          (item, index) => (
                                            <tr key={index}>
                                              <th>{index + 1}</th>
                                              <td>
                                                <Link
                                                  to={`/children/view/${item._id}`}
                                                >
                                                  {item.roll_no}
                                                </Link>
                                              </td>
                                              <td>{item.name}</td>
                                              <td>{item.gender}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              ) : (
                                <CardHeader>No child assigned</CardHeader>
                              )}
                              {!childInfoLoading &&
                                batchInfo.children.length > 0 && (
                                  <Row className="mt-2">
                                    <Col md="5">
                                      <p>
                                        <strong>
                                          {' '}
                                          Showing{' '}
                                          {(childPagination.currentPage - 1) *
                                            childPagination.pageLimit +
                                            1}{' '}
                                          to{' '}
                                          {Math.min(
                                            childPagination.currentPage *
                                            childPagination.pageLimit,
                                            childPagination.totalEntries
                                          )}{' '}
                                          of {childPagination.totalEntries}{' '}
                                          entries
                                        </strong>
                                      </p>
                                    </Col>

                                    <Col md="7">
                                      <Paginator
                                        showOnlyIndicators={true}
                                        totalPages={childPagination.totalPages}
                                        currentPage={
                                          childPagination.currentPage
                                        }
                                        changePage={handleChildPagination}
                                      />
                                    </Col>
                                  </Row>
                                )}
                            </>
                          )}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <Row className="mb-3">
                            <Col md="5">
                              <CardTitle tag="h5">Activities</CardTitle>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                              <Button
                                size="sm"
                                color="primary"
                                className=" waves-effect waves-light mr-3"
                                onClick={openFilterModal}
                              >
                                <i className="ri-filter-fill align-middle mr-2"></i>
                                <span>Filter Activity</span>
                                {currentFilterCount > 0 && (
                                  <Badge color="light" pill className="ml-2">
                                    {currentFilterCount}
                                  </Badge>
                                )}
                              </Button>
                              {validatePermissionKey(
                                props.authUser,
                                ASSIGN_ACTIVITIES
                              ) &&
                                !batchInfo.isDeleted && (
                                  <Button
                                    size="sm"
                                    color="success"
                                    className=" waves-effect waves-light"
                                    onClick={() => {
                                      history.push({
                                        pathname: `/batches/activity/${props.match.params.batchId}`,
                                        state: {
                                          batchStartDate: startDate,
                                          batchEndDate: endDate,
                                          batchStartTime: startTime,
                                          authUser: props.authUser
                                        }
                                      });
                                    }}
                                  >
                                    <i className="ri-add-line align-middle mr-2"></i>
                                    Add/Remove Activities
                                  </Button>
                                )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Col md="12">
                                  <Label className="col-form-label">
                                    Show Entries
                                  </Label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      fetchActivityInfo(
                                        selectedDate,
                                        filterName,
                                        1,
                                        e.target.value
                                      );
                                      setActivityPagination({
                                        ...activityPagination,
                                        pageLimit: e.target.value
                                      });
                                    }}
                                    value={activityPagination.pageLimit}
                                    disabled={activityInfoLoading}
                                  >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={100}>100</option>
                                  </select>
                                </Col>
                              </FormGroup>
                            </Col>
                            {props?.authUser?.user_type === 'super_admin' && (
                              <Col className="d-flex justify-content-end align-items-center">
                                <Button
                                  size="sm"
                                  color="danger"
                                  className=" waves-effect waves-light mr-3"
                                  onClick={openDeleteActivitiesModal}
                                >
                                  <i className="fas fa-trash mr-2"></i>
                                  <span>Delete All Activities</span>
                                </Button>
                              </Col>
                            )}
                          </Row>

                          {activityInfoLoading ? (
                            <div className="d-flex justify-content-center">
                              <PageDataLoader />
                            </div>
                          ) : (
                            <>
                              {activities && activities.length > 0 ? (
                                <div className="table-rep-plugin">
                                  <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                  >
                                    <Table
                                      id="tech-companies-1"
                                      striped
                                      bordered
                                      responsive
                                    >
                                      <thead>
                                        <tr>
                                          <th>SL. No</th>
                                          <th>Name</th>
                                          <th>Duration</th>
                                          <th>Date</th>
                                          <th>Start Time</th>
                                          {validatePermissionKey(
                                            props.authUser,
                                            ADD_EXECUTION
                                          ) && <th>Execution</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {activities?.map((item, index) => (
                                          <tr key={index}>
                                            <th>{index + 1}</th>
                                            <td>{item.activity.name}</td>
                                            <td>{item.activity.duration}</td>
                                            <td>
                                              {moment(
                                                new Date(item.date)
                                              ).format('DD-MM-YYYY')}
                                            </td>
                                            <td>{item.start_time}</td>
                                            {validatePermissionKey(
                                              props.authUser,
                                              ADD_EXECUTION
                                            ) &&
                                              moment(
                                                new Date(item.date)
                                              ).format('YYYY-MM-DD') <=
                                              moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ) && (
                                                <td>
                                                  <Button
                                                    size="sm"
                                                    color="info"
                                                    className=" waves-effect waves-light"
                                                    onClick={() =>
                                                      history.push(
                                                        `/executions/create/${props.match.params.batchId}/${item._id}`
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-add-line align-middle mr-2"></i>
                                                    Add
                                                  </Button>
                                                </td>
                                              )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              ) : (
                                <CardHeader>
                                  {activityError
                                    ? activityError
                                    : 'No activity assigned'}
                                </CardHeader>
                              )}
                              {!activityInfoLoading &&
                                activities.length > 0 && (
                                  <Row className="mt-2">
                                    <Col md="5">
                                      <p>
                                        <strong>
                                          {' '}
                                          Showing{' '}
                                          {(activityPagination.currentPage -
                                            1) *
                                            activityPagination.pageLimit +
                                            1}{' '}
                                          to{' '}
                                          {Math.min(
                                            activityPagination.currentPage *
                                            activityPagination.pageLimit,
                                            activityPagination.totalEntries
                                          )}{' '}
                                          of {activityPagination.totalEntries}{' '}
                                          entries
                                        </strong>
                                      </p>
                                    </Col>

                                    <Col md="7">
                                      <Paginator
                                        showOnlyIndicators={true}
                                        totalPages={
                                          activityPagination.totalPages
                                        }
                                        currentPage={
                                          activityPagination.currentPage
                                        }
                                        changePage={handleActivityPagination}
                                      />
                                    </Col>
                                  </Row>
                                )}
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : activeTab === 'notice' ? (
                <>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-end align-items-center">
                      {validatePermissionKey(props.authUser, CREATE_NOTICE) && (
                        <Button
                          size="sm"
                          color="success"
                          className=" waves-effect waves-light"
                          onClick={openAddNoticeModal}
                        >
                          <i className="ri-add-line align-middle mr-2"></i>
                          Add Notice
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {noticeInfoLoading ? (
                    <div className="d-flex justify-content-center">
                      <PageDataLoader />
                    </div>
                  ) : (
                    <>
                      {noticeInfo && noticeInfo.length > 0 ? (
                        <div id="accordion">
                          {noticeInfo.map((n, i) => (
                            <>
                              <Card className="mb-3">
                                <div
                                  onClick={() => {
                                    toggleNotice(i);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  className="text-dark"
                                >
                                  <CardBody>
                                    <h6 className="m-0 font-14">
                                      {moment(new Date(n.date)).format(
                                        'DD/MM/YYYY'
                                      )}
                                      {' | '}
                                      {n.subject}
                                      <i
                                        className={
                                          open === i
                                            ? 'mdi mdi-minus float-right accor-plus-icon'
                                            : 'mdi mdi-plus float-right accor-plus-icon'
                                        }
                                      ></i>
                                    </h6>
                                  </CardBody>
                                </div>
                                <Collapse isOpen={open === i}>
                                  <hr className="mt-0 mb-0" />
                                  <CardBody>{n.description}</CardBody>
                                  <CardFooter>
                                    {validatePermissionKey(
                                      props.authUser,
                                      UPDATE_NOTICE
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          className=" waves-effect waves-light mr-2 mb-2"
                                          onClick={() => {
                                            openUpdateNoticeModal(n._id);
                                          }}
                                        >
                                          <i className="fas fa-edit mr-2"></i>
                                          Edit
                                        </Button>
                                      )}
                                    {validatePermissionKey(
                                      props.authUser,
                                      DELETE_NOTICE
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="danger"
                                          className=" waves-effect waves-light mb-2"
                                          onClick={() => {
                                            openDeleteNoticeModal(
                                              n._id,
                                              n.isDeleted ? 'restore' : 'delete'
                                            );
                                          }}
                                        >
                                          {n.isDeleted ? (
                                            <i className="fas fa-trash-restore mr-2"></i>
                                          ) : (
                                            <i className="fas fa-trash mr-2"></i>
                                          )}
                                          {n.isDeleted ? 'Restore' : 'Delete'}
                                        </Button>
                                      )}
                                  </CardFooter>
                                </Collapse>
                              </Card>
                            </>
                          ))}
                        </div>
                      ) : (
                        <Card>
                          <CardBody>
                            <CardHeader style={{ textAlign: 'center' }}>
                              No notice added
                            </CardHeader>
                          </CardBody>
                        </Card>
                      )}
                      {!noticeInfoLoading && noticeInfo.length > 0 && (
                        <Row className="mt-2">
                          <Col md="5">
                            <p>
                              <strong>
                                {' '}
                                Showing{' '}
                                {(noticePagination.currentPage - 1) *
                                  noticePagination.pageLimit +
                                  1}{' '}
                                to{' '}
                                {Math.min(
                                  noticePagination.currentPage *
                                  noticePagination.pageLimit,
                                  noticePagination.totalEntries
                                )}{' '}
                                of {noticePagination.totalEntries} entries
                              </strong>
                            </p>
                          </Col>

                          <Col md="7">
                            <Paginator
                              totalPages={noticePagination.totalPages}
                              currentPage={noticePagination.currentPage}
                              changePage={handleNoticePagination}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </>
              ) : activeTab === 'batch_image' ? (
                <>
                  <Row className="mb-3">
                    <Col md="3">
                      <AvForm>
                        <FormGroup>
                          <Label>Date</Label>
                          <AvField
                            name="batchImageDate"
                            placeholder="Enter Date ..."
                            min={
                              batchInfo.start_date &&
                              batchInfo.start_date.split('T')[0]
                            }
                            max={
                              batchInfo.end_date &&
                              batchInfo.end_date.split('T')[0]
                            }
                            type="date"
                            className="form-control"
                            defaultValue={
                              batchImageDate &&
                              moment(new Date(batchImageDate)).format(
                                'YYYY-MM-DD'
                              )
                            }
                            onChange={(e) => {
                              let date = moment(
                                new Date(e.target.value)
                              ).format('YYYY-MM-DD');
                              setBatchImageDate(date);
                              fetchBatchImages(date);
                            }}
                          />
                        </FormGroup>
                      </AvForm>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      {validatePermissionKey(
                        props.authUser,
                        CREATE_BATCH_IMAGE
                      ) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openBatchImageModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Image
                          </Button>
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {batchImagesLoading ? (
                        <div className="text-center">
                          <PageDataLoader />
                        </div>
                      ) : (
                        <>
                          {batchImages.length > 0 ? (
                            <>
                              <ThumbnailGallery
                                images={batchImages}
                                canDelete={true}
                                batch={props.match.params.batchId}
                                date={batchImageDate}
                                fetchNewData={() =>
                                  fetchBatchImages(batchImageDate)
                                }
                                authUser={props.authUser}
                              />
                            </>
                          ) : (
                            <Card>
                              <CardBody>
                                <CardHeader style={{ textAlign: 'center' }}>
                                  No images added
                                </CardHeader>
                              </CardBody>
                            </Card>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mb-2 mt-5 justify-content-center">
                    <Card style={{ width: "100%" }}>
                      {/* <CardHeader>
                        Form Responses
                      </CardHeader> */}
                      <CardBody>

                        <Col md="12">
                          {childrenData.length > 0 &&
                            childrenData.map((child, childIndex) => (
                              <Card>
                                <CardHeader
                                  onClick={() =>
                                    setToggeform(childIndex, child._id)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span className="font-weight-bold">
                                    {child.name}
                                  </span>
                                  {child.badges &&
                                    child.badges.map((badge) => (
                                      <span
                                        key={badge._id}
                                        className="badge badge-pill badge-info ml-1"
                                      >
                                        {badge.name}
                                      </span>
                                    ))}
                                </CardHeader>
                                <Collapse
                                  isOpen={toggleForm === childIndex ? true : false}
                                >
                                  {batchInfo && batchInfo.attributes && batchInfo?.attributes.map((attribute, i) => {
                                    return (
                                      <>
                                        <CardHeader style={{ cursor: 'pointer', backgroundColor: "pink" }} onClick={() => {
                                          toggleSectionForm(i, child._id, attribute._id)
                                        }}
                                        >
                                          <span className="font-weight-bold">
                                            {i + 1}. Attribute -  {attribute.name}
                                          </span>
                                        </CardHeader>
                                        <Collapse isOpen={sectionToggle === i ? true : false} >
                                          {sectionLoading ? (
                                            <div className="text-center">
                                              <PageDataLoader />
                                            </div>
                                          ) : (
                                            <>
                                              <CardBody>
                                                <Row className='mb-3'>

                                                  <Col md="3">
                                                    <Label>Child's Weightage</Label>
                                                    <Input
                                                      type='text'
                                                      disabled
                                                      value={sectionWeightage.childWeightage}
                                                    />
                                                  </Col>
                                                  <Col md="3">
                                                    <Label>Total Weightage</Label>
                                                    <Input
                                                      type='text'
                                                      disabled
                                                      value={sectionWeightage.totalWeightage}
                                                    />
                                                  </Col>
                                                  <Col md="3">
                                                    <Label>Assigned Badge</Label>
                                                    <Input
                                                      type='text'
                                                      disabled
                                                      value={sectionResponse?.badge ? sectionResponse.badge.name : "None"}
                                                    />
                                                  </Col>
                                                </Row>

                                                <Table >
                                                  <tr>
                                                    <th>S.No</th>
                                                    <th>Question</th>
                                                    <th>Options</th>
                                                  </tr>

                                                  {sectionResponse && sectionResponse?.section_data
                                                    ?.questions.length > 0 &&
                                                    sectionResponse.section_data?.questions.map(
                                                      (
                                                        question,
                                                        questionIndex
                                                      ) => {

                                                        return (
                                                          <tbody>
                                                            <tr>
                                                              <td style={{ width: "10%" }}>
                                                                {questionIndex + 1}
                                                              </td>
                                                              <td style={{ width: "45%" }}>
                                                                {question?.title}
                                                              </td>


                                                              <td>
                                                                <div className="radio-options">
                                                                  {question?.option_list.map(
                                                                    (
                                                                      option,
                                                                      optionIndex
                                                                    ) => (
                                                                      <FormGroup
                                                                        key={
                                                                          option._id
                                                                        }
                                                                        check
                                                                        className="form-check"
                                                                      >
                                                                        <Input
                                                                          type="radio"
                                                                          id={`option-${option._id}`}
                                                                          // name={`option-${option._id}`}
                                                                          value={
                                                                            option._id
                                                                          }
                                                                          checked={
                                                                            question.answer ===
                                                                              option._id
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onChange={(e) => {
                                                                            handleOptionChange(
                                                                              e,
                                                                              question._id,
                                                                              option
                                                                            )
                                                                          }}
                                                                        />
                                                                        <Label
                                                                          for={`option-${questionIndex}-${optionIndex}`}
                                                                          check
                                                                        >
                                                                          {
                                                                            option.option_name
                                                                          }
                                                                        </Label>
                                                                      </FormGroup>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        )
                                                      }
                                                    )}
                                                </Table>
                                                <div style={{ height: 400, width: '100%', margin: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                  <PieChart width={400} height={400}>
                                                    <Pie
                                                      data={weightageData}

                                                      innerRadius={80} // Makes the chart a donut chart
                                                      outerRadius={120}
                                                      fill="#8884d8"
                                                      dataKey="value"
                                                    >
                                                      {weightageData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                                      ))}
                                                    </Pie>
                                                    <Tooltip />
                                                    <Legend />
                                                  </PieChart>


                                                </div>
                                                <Col className="text-right">
                                                  <Button
                                                    size="sm"
                                                    color="primary"
                                                    className=" waves-effect waves-light flex-end mr-3"
                                                    disabled={sectionResponseLoading}
                                                    onClick={() => {
                                                      handleformsubmit(i, child._id, attribute._id)
                                                    }}
                                                  >
                                                    {sectionResponseLoading ? "Submitting" : "Submit attribute"}
                                                  </Button>
                                                </Col>
                                              </CardBody>
                                              <CardFooter className="text-right" style={{ backgroundColor: "white" }}>

                                              </CardFooter>
                                            </>
                                          )
                                          }
                                        </Collapse>
                                      </>
                                    )
                                  })}
                                </Collapse>
                              </Card>
                            ))}
                        </Col>
                      </CardBody>
                    </Card>
                  </Row>
                </>
              )}
            </Container>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewSingleBatch);

/*jshint esversion: 11 */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Badge,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Nav,
  NavLink,
  TabPane,
  TabContent,
  Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';
import { getChildInfo, getGalleryImages } from '../../api/child';
import { useHistory } from 'react-router-dom';
import PageDataLoader from '../Utility/PageDataLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import ExecutionCarousel from './ExecutionCarousel';
import { getExecutionList } from '../../api/execution';
import Paginator from '../Utility/Paginator';
import {
  CREATE_TRANSACTION,
  DELETE_EXECUTION,
  VIEW_TRANSACTION,
  UPDATE_ASSIGNMENT,
  UPDATE_EXECUTION,
  VIEW_ASSIGNMENTS,
  VIEW_EXECUTIONS,
  VIEW_FEEDBACK_RESPONSE
} from '../Utility/PermissionKeys';
import { validatePermissionKey } from '../../helpers/permission';
import UpdateExecutionModal from './UpdateExecutionModal';
import {
  getAssignmentChildrenInfo,
  getAssignmentChildrenList
} from '../../api/assignment';
import ViewAssignmentModal from './ViewAssignmentModal';
import { fetchImage } from '../../api/image';
import ChildGalleryModal from './ChildGalleryModal';
import ViewFeedbackModal from './ViewFeedbackModal';
import { addCommentGeneral, getFeedbackResponseList } from '../../api/feedback';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import UpdateAssignmentStatusModal from './UpdateAssignmentStatusModal';
import ThumbnailGallery from './ThumbnailGallery';
import DeleteExecutionModal from './DeleteExecutionModal';
import ExecutionFilterModal from './ExecutionFilterModal';
import { getBatchList } from '../../api/batch';
import AddtransactionModal from './AddtransactionModal';
import { getAllTransactionsOfChild } from '../../api/transaction';
import TransactionCard from '../../components/TransactionCard';
import FilterTransactionModal from './FilterTransactionModal';

const ViewSingleChild = (props) => {
  const [path, setPath] = useState('');
  const history = useHistory();
  const [imgUrl, setImgUrl] = useState('');
  const [breadcrumbItems] = useState([
    { title: 'Children', link: '/children' },
    { title: 'View Child', link: '#' }
  ]);
  const [activeTab, setActiveTab] = useState('1');

  const [errorAlert, setErrorAlert] = useState({
    message: '',
    show_alert: false
  });
  const [sortingRule, setSortingRule] = useState('date$desc');
  const [isOpen, setIsOpen] = useState(false);
  const [childInfo, setChildInfo] = useState({});
  const [childInfoLoading, setChildInfoLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [executionInfo, setExecutionInfo] = useState([]);
  const [executionInfoLoading, setExecutionInfoLoading] = useState(true);
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const [feedbackResponseLoading, setFeedbackResponseLoading] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState(null);
  const [assignmentInfo, setAssignmentInfo] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [commentRequestLoading, setCommentRequestLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [assignmentInfoLoading, setAssignmentInfoLoading] = useState(true);
  const [updateExecutionModalOpen, setUpdateExecutionModalOpen] =
    useState(false);
  const [assignmentDetailModalOpen, setAssignmentDetailModalOpen] =
    useState(false);
  const [executionId, setExecutionId] = useState('');
  useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [assignmentDetail, setAssignmentDetail] = useState([]);
  const [assignmentDetailLoading, setAssignmentDetailLoading] = useState(true);
  const [addGalleryModalOpen, setAddGalleryModalOpen] = useState(false);
  const [updateAssignmentModalOpen, setUpdateAssignmentModalOpen] =
    useState(false);
  const [assignmentId, setAssignmentId] = useState('');

  const [transactionModal, setTransactionModal] = useState(false)
  const [filterTransactionModal, setFilterTransactionModal] = useState(false)
  const [transactionLoading, setTransactionLoading] = useState(false)

  useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 3,
    totalEntries: 0,
    totalPages: 0
  });

  const [pagination3, setPagination3] = useState({
    currentPage: 1,
    pageLimit: 3,
    totalEntries: 0,
    totalPages: 0
  });

  const [pagination2, setPagination2] = useState({
    currentPage: 1,
    pageLimit: 6,
    totalEntries: 0,
    totalPages: 0
  });

  const [pagination4, setPagination4] = useState({
    currentPage: 1,
    pageLimit: 9,
    totalEntries: 0,
    totalPages: 0,
    indexOfFirstRecord: 0
  });

  const [collapse, setCollapse] = useState([]);
  const [deleteExecutionModalOpen, setDeleteExecutionModalOpen] =
    useState(false);
  const [executionData, setExecutionData] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const [executionFilterModal, setExecutionFilterModal] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null
  });
  // eslint-disable-next-line no-unused-vars
  const [batchQueryId, setBatchQueryId] = useState([]);
  const [filterBatchList, setFilterBatchList] = useState([]);
  const [transactionList, setTransactionList] = useState([])
  const [selectedFilterBatchOption, setSelectedFilterBatchOption] = useState(
    []
  );

  async function fetchBatchOption() {
    const response = await getBatchList(1, 100);
    if (response) {
      setFilterBatchList(response?.data);
    }
  }

  async function fetchTransactions(id, filters = {}) {
    setTransactionLoading(true)
    const response = await getAllTransactionsOfChild({
      child_id: id,
      ...filters
    });
    if (response?.success) {
      setTransactionList(response?.data);
    }
    else {
      setErrorAlert("Error in fetching Transaction")
    }
    setTransactionLoading(false)
  }

  const filterBatchOptions = useMemo(
    () => buildBatchOptions(filterBatchList),
    // eslint-disable-next-line
    [filterBatchList]
  );

  function buildBatchOptions(roleArray) {
    const batchOptions = roleArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedBatchOption = batchOptions.filter((i) =>
      batchQueryId.includes(i.value._id)
    );
    setSelectedFilterBatchOption(selectedBatchOption);
    return batchOptions;
  }

  async function openDeleteExecutionModal(executionId, requestType) {
    setExecutionData({
      executionId,
      requestType
    });
    setDeleteExecutionModalOpen(true);
  }

  function closeDeleteExecutionModal() {
    setDeleteExecutionModalOpen(false);
  }

  const toggleCollapse = (index) => {
    let collapseCopy = [...collapse];
    collapseCopy[index] = !collapseCopy[index];
    setCollapse(collapseCopy);
  };
  let listElem = document.getElementById('childList');
  let linkElem = document.getElementById('childLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    setPath(window.location.href);
    let params = props.location.search.split('=')[1];
    if (params === 'executions') setActiveTab('2');
    else if (params === 'assignments') setActiveTab('3');
    else if (params === 'gallery') setActiveTab('4');
    else if (params === 'feedback') setActiveTab('5');
    else setActiveTab('1');
    fetchChildInfo();
    fetchExecutionInfo();
    fetchAssignmentInfo();
    fetchGalleryImages();
    fetchFeedbackInfo();
    fetchBatchOption();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, listElem, linkElem]);

  async function fetchChildInfo() {
    setChildInfoLoading(true);
    const response = await getChildInfo(props.match.params.childId);
    if (response) {
      setChildInfo(response);
      fetchTransactions(response?._id);
      setChildInfoLoading(false);
    } else {
      setErrorAlert({
        message: 'Child details not found',
        show_alert: true
      });
      setChildInfoLoading(false);
    }
  }

  const handlePagination = (page) => {
    fetchExecutionInfo(page);
  };

  const handlePagination2 = (page) => {
    fetchAssignmentInfo(page);
  };

  const handlePagination3 = (page) => {
    fetchFeedbackInfo(page);
  };

  const handlePagination4 = (page) => {
    fetchGalleryImages(page);
  };

  const fetchExecutionInfo = async (
    current_page = 1,
    page_limit = 3,
    deletedStatus = isDeleted,
    sorting_option = sortingRule,
    filter_batch = selectedFilterBatchOption,
    filter_start_date = dateFilter.startDate,
    filter_end_date = dateFilter.endDate
  ) => {
    setExecutionInfoLoading(true);
    let selected_batch = '';
    selected_batch = Array.isArray(filter_batch)
      ? filter_batch.map((i) => i.value._id).join(',')
      : '';
    let filterCount = 0;

    if (selected_batch) filterCount += 1;
    if (filter_start_date && filter_end_date) filterCount += 1;
    if (isDeleted) filterCount += 1;
    setCurrentFilterCount(filterCount);

    const executionArray = await getExecutionList(
      props.match.params.childId,
      current_page,
      page_limit,
      deletedStatus,
      selected_batch,
      filter_start_date,
      filter_end_date,
      sorting_option
    );

    if (executionArray) {
      setPagination({
        currentPage: executionArray.metadata.page,
        pageLimit: executionArray.metadata.limit,
        totalPages: executionArray.metadata.total_pages,
        totalEntries: executionArray.metadata.total_results
      });
      setExecutionInfo(executionArray.data);
      setExecutionInfoLoading(false);
    }
  };

  const fetchFeedbackInfo = async (current_page = 1, page_limit = 3) => {
    setFeedbackResponseLoading(true);
    const responseArray = await getFeedbackResponseList(
      props.match.params.childId,
      current_page,
      page_limit
    );

    if (responseArray) {
      setPagination3({
        currentPage: responseArray.metadata.page,
        pageLimit: responseArray.metadata.limit,
        totalPages: responseArray.metadata.total_pages,
        totalEntries: responseArray.metadata.total_results
      });
      setFeedbackResponse(responseArray.data);
      setFeedbackResponseLoading(false);
    }
  };

  const fetchAssignmentInfo = async (current_page = 1, page_limit = 6) => {
    setAssignmentInfoLoading(true);
    const assignmentArray = await getAssignmentChildrenList({
      child: props.match.params.childId,
      page: current_page,
      limit: page_limit
    });

    if (assignmentArray) {
      setPagination2({
        currentPage: assignmentArray.metadata.page,
        pageLimit: assignmentArray.metadata.limit,
        totalPages: assignmentArray.metadata.total_pages,
        totalEntries: assignmentArray.metadata.total_results
      });
      setAssignmentInfo(assignmentArray.data);
      setAssignmentInfoLoading(false);
    }
  };

  const fetchGalleryImages = async (current_page = 1, page_limit = 9) => {
    setGalleryLoading(true);
    const galleryArray = await getGalleryImages(props.match.params.childId);

    if (galleryArray) {
      const indexOfLastRecord = current_page * page_limit;
      const indexOfFirstRecord = indexOfLastRecord - page_limit;
      setPagination4({
        currentPage: current_page,
        pageLimit: page_limit,
        totalPages: Math.ceil(galleryArray.length / page_limit),
        totalEntries: galleryArray.length,
        indexOfFirstRecord
      });
      setGallery(galleryArray);
      const currentRecords = galleryArray.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setGalleryImages(currentRecords);
      setGalleryLoading(false);
    }
  };

  function openUpdateExecutionModal(execution) {
    setExecutionId(execution);
    setUpdateExecutionModalOpen(true);
  }

  function closeUpdateExecutionModal() {
    setUpdateExecutionModalOpen(false);
  }

  function openUpdateAssignmentModal(assignment) {
    setAssignmentId(assignment);
    setUpdateAssignmentModalOpen(true);
  }

  function closeUpdateAssignmentModal() {
    setUpdateAssignmentModalOpen(false);
  }

  async function fetchAssignmentDetails(assignmentId) {
    setAssignmentDetailLoading(true);
    const response = await getAssignmentChildrenInfo(assignmentId);
    if (response) {
      setAssignmentDetail(response);
      setAssignmentDetailLoading(false);
    }
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function openAssignmentDetailModal(assignmentId) {
    fetchAssignmentDetails(assignmentId);
    setAssignmentDetailModalOpen(true);
  }

  function closeAssignmentDetailModal() {
    setAssignmentDetailModalOpen(false);
  }

  function openAddGalleryModal() {
    setAddGalleryModalOpen(true);
  }

  function closeAddGalleryModal() {
    setAddGalleryModalOpen(false);
  }

  function openFeedbackModal(id, data) {
    setExecutionId(id);
    setFeedbackInfo(data);
    setFeedbackModal(true);
  }

  function closeFeedbackModal() {
    setFeedbackModal(false);
  }

  const getImage = async (key) => {
    setImageLoading(true);
    let response = await fetchImage(key);
    if (response == null)
      return
    setImgUrl(URL.createObjectURL(response));
    setImageLoading(false);
  };

  const handleComment = async (feedbackId, responseId) => {
    setCommentRequestLoading(true);
    let { response } = await addCommentGeneral(feedbackId, responseId, comment);
    if (response) {
      setComment('');
      setIsOpen(!isOpen);
      fetchFeedbackInfo();
    }
    setCommentRequestLoading(false);
  };


  function closeTrasnactionModal() {
    setFilterTransactionModal(false);
  }
  useEffect(() => {
    let key;
    if (childInfo.profile_picture && childInfo.profile_picture.documentKey) {
      key = childInfo.profile_picture.documentKey;
    } else {
      key = process.env.REACT_APP_PROFILE_IMG;
    }
    getImage(key);
  }, [childInfo]);
  return (
    <React.Fragment>
      <div className="page-content">
        {errorAlert.show_alert && (
          <SweetAlert
            danger
            title=""
            onConfirm={() => history.push('/children')}
          >
            {errorAlert.message}
          </SweetAlert>
        )}
        <ChildGalleryModal
          modalOpenState={addGalleryModalOpen}
          closeModalHandler={closeAddGalleryModal}
          child={props.match.params.childId}
          fetchNewData={fetchGalleryImages}
          authUser={props.authUser}
        />

        <AddtransactionModal
          modalOpenState={transactionModal}
          closeModalHandler={() => setTransactionModal(false)}
          authUser={props.authUser}
          childId={childInfo?._id}
          childinfo={childInfo}
          refresh={() => fetchTransactions(childInfo?._id)}
        />

        <FilterTransactionModal
          modalOpenState={filterTransactionModal}
          closeModalHandler={closeTrasnactionModal}
          authUser={props.authUser}
          childId={childInfo?._id}
          childinfo={childInfo}
          refresh={(filters) => fetchTransactions(childInfo?._id, filters)}
          setCurrentFilterCount={setCurrentFilterCount}

        />

        <ExecutionFilterModal
          authUser={props.authUser}
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={executionFilterModal}
          closeModalHandler={() => setExecutionFilterModal(false)}
          createdAtFilter={dateFilter}
          setCreatedAtFilter={setDateFilter}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
          batchOptions={filterBatchOptions}
          selectedBatchOption={selectedFilterBatchOption}
          setSelectedBatchOption={setSelectedFilterBatchOption}
          fetchList={fetchExecutionInfo}
        />
        {assignmentInfo && (
          <>
            <ViewAssignmentModal
              modalOpenState={assignmentDetailModalOpen}
              closeModalHandler={closeAssignmentDetailModal}
              assignmentInfo={assignmentDetail}
              assignmentInfoLoading={assignmentDetailLoading}
            />
            <UpdateAssignmentStatusModal
              modalOpenState={updateAssignmentModalOpen}
              closeModalHandler={closeUpdateAssignmentModal}
              assignmentId={assignmentId}
              fetchNewData={fetchAssignmentInfo}
              authUser={props.authUser}
            />
          </>
        )}
        {executionInfo && (
          <>
            <UpdateExecutionModal
              modalOpenState={updateExecutionModalOpen}
              closeModalHandler={closeUpdateExecutionModal}
              executionId={executionId}
              fetchNewData={fetchExecutionInfo}
              authUser={props.authUser}
            />
            <ViewFeedbackModal
              modalOpenState={feedbackModal}
              closeModalHandler={closeFeedbackModal}
              feedbackInfo={feedbackInfo}
              executionId={executionId}
              fetchNewData={fetchExecutionInfo}
            />
            {executionData && (
              <DeleteExecutionModal
                modalOpenState={deleteExecutionModalOpen}
                closeModalHandler={closeDeleteExecutionModal}
                executionId={executionData.executionId}
                type={executionData.requestType}
                fetchNewData={fetchExecutionInfo}
              />
            )}
          </>
        )}
        {childInfoLoading ? (
          <div className="text-center">
            <PageDataLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title="Child Details"
              breadcrumbItems={breadcrumbItems}
            />

            <Row className="mb-4">
              {/* Child Information */}
              <Col sm={12} md={4}>
                {/* Profile Pic and Name */}
                <Row>
                  <Col sm={12}>
                    {imageLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <CardImg
                        className="rounded mr-2"
                        height="350"
                        width="350"
                        src={imgUrl}
                        alt={childInfo?.name}
                        style={{
                          background: 'rgba(255, 255, 255)',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                    <Card className="mt-2 mb-1 p-2">
                      <h1 className="display-5">{childInfo?.name}</h1>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <Card className="mt-2 mb-1 p-2">
                      <Nav
                        pills
                        className="flex-column"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <NavLink
                          id="v-pills-home-tab"
                          style={{ cursor: 'pointer' }}
                          className={classnames(
                            {
                              active: activeTab === '1'
                            },
                            'mb-2'
                          )}
                          onClick={() => {
                            window.history.pushState(
                              null,
                              null,
                              path + '?tab=details'
                            );
                            toggleTab('1');
                          }}
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <i className=" ri-account-circle-line align-middle mr-2"></i>{' '}
                          Basic Details
                        </NavLink>
                        {validatePermissionKey(
                          props.authUser,
                          VIEW_EXECUTIONS
                        ) && (
                            <NavLink
                              id="v-pills-profile-tab"
                              style={{ cursor: 'pointer' }}
                              className={classnames(
                                {
                                  active: activeTab === '2'
                                },
                                'mb-2'
                              )}
                              onClick={() => {
                                window.history.pushState(
                                  null,
                                  null,
                                  path + '?tab=executions'
                                );
                                toggleTab('2');
                              }}
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              <i className=" ri-pencil-ruler-2-line align-middle mr-2"></i>{' '}
                              Executions
                            </NavLink>
                          )}
                        {validatePermissionKey(
                          props.authUser,
                          VIEW_ASSIGNMENTS
                        ) && (
                            <NavLink
                              id="v-pills-messages-tab"
                              style={{ cursor: 'pointer' }}
                              className={classnames(
                                {
                                  active: activeTab === '3'
                                },
                                'mb-2'
                              )}
                              onClick={() => {
                                window.history.pushState(
                                  null,
                                  null,
                                  path + '?tab=assignments'
                                );
                                toggleTab('3');
                              }}
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              <i className=" ri-survey-line align-middle mr-2"></i>{' '}
                              Assignments
                            </NavLink>
                          )}
                        <NavLink
                          id="v-pills-messages-tab"
                          style={{ cursor: 'pointer' }}
                          className={classnames(
                            {
                              active: activeTab === '4'
                            },
                            'mb-2'
                          )}
                          onClick={() => {
                            window.history.pushState(
                              null,
                              null,
                              path + '?tab=gallery'
                            );
                            toggleTab('4');
                          }}
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <i className=" ri-gallery-line align-middle mr-2"></i>{' '}
                          Gallery
                        </NavLink>
                        {validatePermissionKey(
                          props.authUser,
                          VIEW_FEEDBACK_RESPONSE
                        ) && (
                            <NavLink
                              id="v-pills-messages-tab"
                              style={{ cursor: 'pointer' }}
                              className={classnames(
                                {
                                  active: activeTab === '5'
                                },
                                'mb-2'
                              )}
                              onClick={() => {
                                window.history.pushState(
                                  null,
                                  null,
                                  path + '?tab=feedback'
                                );
                                toggleTab('5');
                              }}
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              <i className=" ri-contacts-line align-middle mr-2"></i>{' '}
                              General Feedback
                            </NavLink>
                          )}

                        {validatePermissionKey(
                          props.authUser,
                          VIEW_TRANSACTION
                        ) && (
                            <NavLink
                              id="v-pills-messages-tab"
                              style={{ cursor: 'pointer' }}
                              className={classnames(
                                {
                                  active: activeTab === '6'
                                },
                                'mb-2'
                              )}
                              onClick={() => {
                                window.history.pushState(
                                  null,
                                  null,
                                  path + '?tab=transaction'
                                );
                                toggleTab('6');
                              }}
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              <i className="ri-money-dollar-circle-line align-middle mr-2"></i>{' '}
                              Transactions
                            </NavLink>
                          )}

                      </Nav>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col md={8}>
                <TabContent
                  activeTab={activeTab}
                  className="text-muted mt-4 mt-md-0"
                  id="v-pills-tabContent"
                >
                  <TabPane
                    tabId="1"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <Row>
                      <Col sm={12}>
                        <Card className="mb-1 p-3">
                          <CardTitle id="headingOne">
                            <h5
                              className="text-muted m-0 font-14"
                              style={{ fontWeight: 600 }}
                            >
                              Basic Details
                            </h5>
                          </CardTitle>
                          <Row>
                            <Col sm={12}>
                              <hr />
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Roll no</Label>
                                <Input
                                  name="roll_no"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.roll_no}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.name}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label>Email</Label>
                                <Input
                                  name="email_address"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.email_address}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Phone Number</Label>
                                <Input
                                  name="phone_number"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.phone_number}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label>DOB</Label>
                                <Input
                                  name="dob"
                                  type="date"
                                  className="form-control"
                                  defaultValue={
                                    childInfo &&
                                    moment(new Date(childInfo.dob)).format(
                                      'YYYY-MM-DD'
                                    )
                                  }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Gender</Label>
                                <Input
                                  name="time"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.gender}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup>
                                <Label>Address</Label>
                                <Input
                                  name="address"
                                  type="textarea"
                                  className="form-control"
                                  defaultValue={childInfo?.address}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <Label>Address Location</Label>
                                <Input
                                  name="address_location"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo.address_location}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Blood Group</Label>
                                <Input
                                  name="blood_group"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.blood_group}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Allergies</Label>
                                <Input
                                  name="allergies"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.allergies}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Good Habits</Label>
                                <Input
                                  name="good_habits"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.good_habits}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Bad Habits</Label>
                                <Input
                                  name="bad_habits"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.bad_habits}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Sleeping Pattern</Label>
                                <Input
                                  name="sleeping_pattern"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.sleeping_pattern}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Mother Tongue</Label>
                                <Input
                                  name="mother_tongue"
                                  type="text"
                                  className="form-control"
                                  defaultValue={childInfo?.mother_tongue}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md="8">
                              <FormGroup>
                                <Label>Languages Spoken</Label>
                                <div>
                                  {childInfo.languages_spoken &&
                                    childInfo.languages_spoken.map((l) => (
                                      <Badge
                                        color="primary"
                                        className="mr-2 mb-2"
                                        style={{
                                          padding: '10px 20px',
                                          fontSize: '14px'
                                        }}
                                        pill
                                      >
                                        {l}
                                      </Badge>
                                    ))}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>

                    {/* Parents Details */}
                    <Row>
                      <Col md={12}>
                        <Card className="mb-1 p-3">
                          <CardTitle id="headingOne">
                            <h5
                              className="text-muted m-0 font-14"
                              style={{ fontWeight: 600 }}
                            >
                              Parent Details
                            </h5>
                          </CardTitle>

                          <Col sm={12}>
                            <hr />
                          </Col>
                          <Col sm={12}>
                            {childInfo.parents &&
                              childInfo.parents.length > 0 ? (
                              <div className="table-rep-plugin">
                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns"
                                >
                                  <Table
                                    id="tech-companies-1"
                                    striped
                                    bordered
                                    responsive
                                  >
                                    <thead>
                                      <tr>
                                        <th>SL. No</th>
                                        <th>Name</th>
                                        <th>Relation</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {childInfo.parents?.map((item, index) => (
                                        <tr key={index}>
                                          <th>{index + 1}</th>
                                          <td>{item.parent.name}</td>
                                          <td>{item.relation}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            ) : (
                              <CardHeader>No parent assigned</CardHeader>
                            )}
                          </Col>
                        </Card>
                      </Col>
                    </Row>

                    {/* Batch Details */}
                    <Row className="mb-4">
                      <Col md={12}>
                        <Card className="mb-1 p-3">
                          <CardTitle id="headingOne">
                            <h5
                              className="text-muted m-0 font-14"
                              style={{ fontWeight: 600 }}
                            >
                              Batch Details
                            </h5>
                          </CardTitle>

                          <Col sm={12}>
                            <hr />
                          </Col>
                          <Col sm={12}>
                            {childInfo.batches &&
                              childInfo.batches.length > 0 ? (
                              <div className="table-rep-plugin">
                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns"
                                >
                                  <Table
                                    id="tech-companies-1"
                                    striped
                                    bordered
                                    responsive
                                  >
                                    <thead>
                                      <tr>
                                        <th>SL. No</th>
                                        <th>Name</th>
                                        <th>Schedule</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {childInfo.batches?.map((item, index) => (
                                        <tr key={index}>
                                          <th>{index + 1}</th>
                                          <td>{item.name}</td>
                                          <td>{item.schedule}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            ) : (
                              <CardHeader>No batch assigned</CardHeader>
                            )}
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  {validatePermissionKey(props.authUser, VIEW_EXECUTIONS) && (
                    <TabPane
                      tabId="2"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label className="col-form-label">
                              Sort Executions
                            </Label>
                            <select
                              className="form-control"
                              onChange={async (e) => {
                                setSortingRule(e.target.value);
                                await fetchExecutionInfo(
                                  1,
                                  pagination.pageLimit,
                                  isDeleted,
                                  e.target.value
                                );
                              }}
                              value={sortingRule}
                              disabled={executionInfoLoading}
                            >
                              <option value="date$desc">
                                Date (Newest First)
                              </option>
                              <option value="date$asc">
                                Date (Oldest First)
                              </option>
                              <option value="updated_at$desc">
                                Update Time (Newest First)
                              </option>
                              <option value="updated_at$asc">
                                Update Time (Oldest First)
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          <Button
                            size="sm"
                            color="primary"
                            className=" waves-effect waves-light mr-3"
                            onClick={() => setExecutionFilterModal(true)}
                          >
                            <i className="ri-filter-fill align-middle mr-2"></i>
                            <span>Filter Execution</span>
                            {currentFilterCount > 0 && (
                              <Badge color="light" pill className="ml-2">
                                {currentFilterCount}
                              </Badge>
                            )}
                          </Button>
                        </Col>
                      </Row>
                      {executionInfoLoading ? (
                        <div className="text-center">
                          <PageDataLoader />
                        </div>
                      ) : (
                        <>
                          {executionInfo.length > 0 ? (
                            <>
                              {executionInfo.map((e) => (
                                <Row>
                                  <Col sm={12}>
                                    <Card>
                                      <CardBody>
                                        <Row className="mb-1">
                                          <Col>
                                            <Badge
                                              className="mr-2 mb-2"
                                              style={{
                                                padding: '10px 20px',
                                                fontSize: '14px',
                                                background:
                                                  e.batch.offering.color
                                              }}
                                              pill
                                            >
                                              {e.batch.offering.name}
                                            </Badge>
                                          </Col>

                                          <Col className="d-flex justify-content-end align-items-center">
                                            {e.executionType === 'activity' &&
                                              e.feedback_response &&
                                              e.feedback_response.length > 0 &&
                                              validatePermissionKey(
                                                props.authUser,
                                                VIEW_FEEDBACK_RESPONSE
                                              ) && (
                                                <Button
                                                  size="sm"
                                                  color="primary"
                                                  className=" waves-effect waves-light mr-2 mb-2"
                                                  onClick={() =>
                                                    openFeedbackModal(e._id, e)
                                                  }
                                                >
                                                  <i className="fas fa-eye mr-2"></i>
                                                  Feedback
                                                </Button>
                                              )}
                                            {validatePermissionKey(
                                              props.authUser,
                                              UPDATE_EXECUTION
                                            ) && (
                                                <Button
                                                  size="sm"
                                                  color="warning"
                                                  className=" waves-effect waves-light mr-2 mb-2"
                                                  onClick={() =>
                                                    openUpdateExecutionModal(
                                                      e._id
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-edit mr-2"></i>
                                                  Edit
                                                </Button>
                                              )}
                                            {validatePermissionKey(
                                              props.authUser,
                                              DELETE_EXECUTION
                                            ) && (
                                                <Button
                                                  size="sm"
                                                  color="danger"
                                                  className=" waves-effect waves-light mb-2"
                                                  onClick={() => {
                                                    openDeleteExecutionModal(
                                                      e._id,
                                                      e.isDeleted
                                                        ? 'restore'
                                                        : 'delete'
                                                    );
                                                  }}
                                                >
                                                  {e.isDeleted ? (
                                                    <i className="fas fa-trash-restore mr-2"></i>
                                                  ) : (
                                                    <i className="fas fa-trash mr-2"></i>
                                                  )}
                                                  {e.isDeleted
                                                    ? 'Restore'
                                                    : 'Delete'}
                                                </Button>
                                              )}
                                          </Col>
                                        </Row>
                                        <Row className="mb-1">
                                          <Col>
                                            <h4 className="card-title">
                                              {e.executionType === 'activity'
                                                ? e.activity.name +
                                                ' (' +
                                                e.batch.name +
                                                ')'
                                                : e.batch.name}
                                            </h4>
                                            <h6>
                                              {e.executionType === 'activity'
                                                ? '(Activity Execution)'
                                                : '(Batch Execution)'}
                                            </h6>
                                          </Col>
                                        </Row>
                                        {e.executionType === 'activity' && (
                                          <Row>
                                            <Col>
                                              <p>{e.activity.summary}</p>
                                              <hr />
                                            </Col>
                                          </Row>
                                        )}
                                        <Row>
                                          <Col>
                                            <h5>Note: </h5>
                                          </Col>
                                          <Col className="d-flex justify-content-end align-items-center">
                                            <Badge
                                              className="mr-2 mb-2"
                                              color={
                                                e.isPresent
                                                  ? 'success'
                                                  : 'danger'
                                              }
                                              style={{
                                                padding: '10px 20px',
                                                fontSize: '14px'
                                              }}
                                              pill
                                            >
                                              {e.isPresent
                                                ? 'Present'
                                                : 'Absent'}
                                            </Badge>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <p className="card-title-desc">
                                              {e.note}
                                            </p>
                                          </Col>
                                        </Row>
                                        <ExecutionCarousel executionID={e._id} images={e.images} fetchChildInfo={fetchChildInfo} fetchExecutionInfo={fetchExecutionInfo} />
                                      </CardBody>
                                      <CardFooter className="text-muted" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        {moment(new Date(e.date)).format(
                                          'DD-MM-YYYY'
                                        )}
                                        {e.draft ? (
                                          <p>Drafted </p>
                                        ) : (
                                          <p>Published </p>
                                        )}
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                </Row>
                              ))}
                              <Row className="mt-2">
                                <Col md="5">
                                  <p>
                                    <strong>
                                      {' '}
                                      Showing{' '}
                                      {(pagination.currentPage - 1) *
                                        pagination.pageLimit +
                                        1}{' '}
                                      to{' '}
                                      {Math.min(
                                        pagination.currentPage *
                                        pagination.pageLimit,
                                        pagination.totalEntries
                                      )}{' '}
                                      of {pagination.totalEntries} entries
                                    </strong>
                                  </p>
                                </Col>

                                <Col md="7">
                                  <Paginator
                                    totalPages={pagination.totalPages}
                                    currentPage={pagination.currentPage}
                                    changePage={handlePagination}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Card>
                              <CardBody>
                                <CardHeader style={{ textAlign: 'center' }}>
                                  No execution added
                                </CardHeader>
                              </CardBody>
                            </Card>
                          )}
                        </>
                      )}
                    </TabPane>
                  )}
                  {validatePermissionKey(props.authUser, VIEW_ASSIGNMENTS) && (
                    <TabPane
                      tabId="3"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      {assignmentInfoLoading ? (
                        <div className="text-center">
                          <PageDataLoader />
                        </div>
                      ) : (
                        <>
                          {assignmentInfo.length > 0 ? (
                            <>
                              <Row className="mb-2">
                                {assignmentInfo.map((e) => (
                                  <Col sm={6}>
                                    <Card>
                                      <CardBody>
                                        <Row className="mb-4">
                                          <Col>
                                            <h4 className="card-title">
                                              {e.assignment.name}
                                            </h4>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <p>
                                              <i className=" ri-calendar-event-line align-middle mr-2"></i>{' '}
                                              {moment(
                                                new Date(e.assignment.date)
                                              ).format('DD-MM-YYYY')}
                                            </p>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <p>
                                              <i className=" ri-trophy-line align-middle mr-2"></i>{' '}
                                              {e.isCompleted
                                                ? 'Completed'
                                                : 'Pending'}
                                            </p>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                      <CardFooter>
                                        <Col>
                                          <Button
                                            size="sm"
                                            color="primary"
                                            className=" waves-effect waves-light mr-2"
                                            onClick={() => {
                                              openAssignmentDetailModal(e._id);
                                            }}
                                          >
                                            <i className="fas fa-eye mr-2"></i>
                                            View
                                          </Button>
                                          {validatePermissionKey(
                                            props.authUser,
                                            UPDATE_ASSIGNMENT
                                          ) && (
                                              <Button
                                                size="sm"
                                                color="warning"
                                                className=" waves-effect waves-light"
                                                onClick={() =>
                                                  openUpdateAssignmentModal(e._id)
                                                }
                                              >
                                                <i className="fas fa-edit mr-2"></i>
                                                Edit
                                              </Button>
                                            )}
                                        </Col>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                              <Row className="mt-2">
                                <Col md="5">
                                  <p>
                                    <strong>
                                      {' '}
                                      Showing{' '}
                                      {(pagination2.currentPage - 1) *
                                        pagination2.pageLimit +
                                        1}{' '}
                                      to{' '}
                                      {Math.min(
                                        pagination2.currentPage *
                                        pagination2.pageLimit,
                                        pagination2.totalEntries
                                      )}{' '}
                                      of {pagination2.totalEntries} entries
                                    </strong>
                                  </p>
                                </Col>

                                <Col md="7">
                                  <Paginator
                                    totalPages={pagination2.totalPages}
                                    currentPage={pagination2.currentPage}
                                    changePage={handlePagination2}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Card>
                              <CardBody>
                                <CardHeader style={{ textAlign: 'center' }}>
                                  No assignment added
                                </CardHeader>
                              </CardBody>
                            </Card>
                          )}
                        </>
                      )}
                    </TabPane>
                  )}

                  <TabPane
                    tabId="4"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    {galleryLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <>
                        {galleryImages.length > 0 ? (
                          <>
                            <Row className="mb-2 ml-1">
                              <Col>
                                <ThumbnailGallery
                                  images={galleryImages}
                                  canDelete={true}
                                  childId={childInfo._id}
                                  fetchChildInfo={fetchGalleryImages}
                                  indexOfFirstRecord={
                                    pagination4.indexOfFirstRecord
                                  }
                                  gallery={gallery}
                                  authUser={props.authUser}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col md="5">

                                <p>
                                  <strong>
                                    {' '}
                                    Showing{' '}
                                    {(pagination4.currentPage - 1) *
                                      pagination4.pageLimit +
                                      1}{' '}
                                    to{' '}
                                    {Math.min(
                                      pagination4.currentPage *
                                      pagination4.pageLimit,
                                      pagination4.totalEntries
                                    )}{' '}
                                    of {pagination4.totalEntries} entries
                                  </strong>
                                </p>
                              </Col>

                              <Col md="7">
                                <Paginator
                                  totalPages={pagination4.totalPages}
                                  currentPage={pagination4.currentPage}
                                  changePage={handlePagination4}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Card>
                            <CardBody>
                              <CardHeader style={{ textAlign: 'center' }}>
                                No images added
                              </CardHeader>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    )}

                    <Row>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="md"
                          color="primary"
                          className=" waves-effect waves-light"
                          onClick={() => openAddGalleryModal()}
                        >
                          Add Images
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  {validatePermissionKey(
                    props.authUser,
                    VIEW_FEEDBACK_RESPONSE
                  ) && (
                      <TabPane
                        tabId="5"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                      >
                        {feedbackResponseLoading ? (
                          <div className="text-center">
                            <PageDataLoader />
                          </div>
                        ) : (
                          <>
                            {feedbackResponse && feedbackResponse.length > 0 ? (
                              <>
                                {feedbackResponse.map((feedback) => (
                                  <Row>
                                    <Col sm={12}>
                                      <Card>
                                        <CardBody>
                                          <Row className="mb-1">
                                            <Col className="d-flex justify-content-end align-items-center">
                                              <h5>
                                                {moment(
                                                  new Date(feedback.createdAt)
                                                ).format('DD-MM-YYYY')}
                                              </h5>
                                            </Col>
                                          </Row>
                                          <Row className="mb-1">
                                            {feedback.response &&
                                              feedback.response.map(
                                                (f, index) => (
                                                  <Col md="12">
                                                    <FormGroup>
                                                      <Label>{f.question}</Label>
                                                      <Input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={f.answer}
                                                        readOnly
                                                      />
                                                    </FormGroup>
                                                    {f.comment ? (
                                                      <>
                                                        <h6
                                                          className="mb-0"
                                                          style={{
                                                            color: '#ed8f8f'
                                                          }}
                                                        >
                                                          Comment By Teacher
                                                        </h6>
                                                        <p>{f.comment}</p>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {f.answer && (
                                                          <>
                                                            <Button
                                                              type="button"
                                                              color="primary"
                                                              size="sm"
                                                              className="mb-1"
                                                              onClick={() =>
                                                                toggleCollapse(
                                                                  f._id
                                                                )
                                                              }
                                                            >
                                                              <i className="ri-add-line align-middle mr-2"></i>
                                                              Comment
                                                            </Button>
                                                            <Collapse
                                                              isOpen={
                                                                collapse[f._id]
                                                              }
                                                              key={f._id}
                                                            >
                                                              <AvForm
                                                                className="needs-validation"
                                                                onValidSubmit={() =>
                                                                  handleComment(
                                                                    feedback._id,
                                                                    f._id
                                                                  )
                                                                }
                                                              >
                                                                <Row>
                                                                  <Col md="12">
                                                                    <FormGroup>
                                                                      <Label>
                                                                        Comment
                                                                      </Label>
                                                                      <AvField
                                                                        name="comment"
                                                                        placeholder="Enter Comment ..."
                                                                        type="text"
                                                                        className="form-control"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          setComment(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        validate={{
                                                                          required:
                                                                          {
                                                                            value: true,
                                                                            errorMessage:
                                                                              'Comment is required'
                                                                          }
                                                                        }}
                                                                      />
                                                                    </FormGroup>
                                                                  </Col>
                                                                  <Col md="12">
                                                                    <Button
                                                                      id={f._id}
                                                                      type="submit"
                                                                      color="success"
                                                                      size="sm"
                                                                    >
                                                                      {commentRequestLoading
                                                                        ? 'Adding...'
                                                                        : 'Submit'}
                                                                    </Button>
                                                                  </Col>
                                                                </Row>
                                                              </AvForm>
                                                            </Collapse>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <hr />
                                                  </Col>
                                                )
                                              )}
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                ))}
                                <Row className="mt-2">
                                  <Col md="5">
                                    <p>
                                      <strong>
                                        {' '}
                                        Showing{' '}
                                        {(pagination3.currentPage - 1) *
                                          pagination3.pageLimit +
                                          1}{' '}
                                        to{' '}
                                        {Math.min(
                                          pagination3.currentPage *
                                          pagination3.pageLimit,
                                          pagination3.totalEntries
                                        )}{' '}
                                        of {pagination3.totalEntries} entries
                                      </strong>
                                    </p>
                                  </Col>

                                  <Col md="7">
                                    <Paginator
                                      totalPages={pagination3.totalPages}
                                      currentPage={pagination3.currentPage}
                                      changePage={handlePagination3}
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <Card>
                                <CardBody>
                                  <CardHeader style={{ textAlign: 'center' }}>
                                    No feedback response found
                                  </CardHeader>
                                </CardBody>
                              </Card>
                            )}
                          </>
                        )}
                      </TabPane>
                    )}

                  {/* =================================TRANSACTIONS================================================= */}

                  {validatePermissionKey(props.authUser, VIEW_TRANSACTION) && (
                    <>
                      {transactionLoading ? (
                        <TabPane
                          tabId="6"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <Row>
                            <Col className="d-flex justify-content-end align-items-center">
                              <Button
                                size="sm"
                                color="primary"
                                className=" waves-effect waves-light mr-3"
                                onClick={() => setFilterTransactionModal(true)}
                              >
                                <i className="ri-filter-fill align-middle mr-2"></i>
                                <span>Filter Transaction</span>
                                {currentFilterCount > 0 && (
                                  <Badge color="light" pill className="ml-2">
                                    {currentFilterCount}
                                  </Badge>
                                )}
                              </Button>
                              {validatePermissionKey(props.authUser, CREATE_TRANSACTION) && (
                                <Button
                                  size="sm"
                                  color="success"
                                  className=" waves-effect waves-light mr-3"
                                  onClick={() => setTransactionModal(true)}
                                >
                                  <i className="ri-add-line align-middle mr-2"></i>
                                  Add Transaction
                                </Button>
                              )}
                            </Col>
                          </Row>

                          <Card className='mt-3'>
                            <CardBody>
                              <CardHeader style={{ textAlign: 'center' }}>
                                LOADING TRANSACTIONS...
                              </CardHeader>
                            </CardBody>
                          </Card>


                        </TabPane>
                      ) : (
                        <TabPane
                          tabId="6"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <Row>
                            <Col className="d-flex justify-content-end align-items-center">
                              <Button
                                size="sm"
                                color="primary"
                                className=" waves-effect waves-light mr-3"
                                onClick={() => setFilterTransactionModal(true)}
                              >
                                <i className="ri-filter-fill align-middle mr-2"></i>
                                <span>Filter Transaction</span>
                                {currentFilterCount > 0 && (
                                  <Badge color="light" pill className="ml-2">
                                    {currentFilterCount}
                                  </Badge>
                                )}
                              </Button>
                              {validatePermissionKey(props.authUser, CREATE_TRANSACTION) && (
                                <Button
                                  size="sm"
                                  color="success"
                                  className=" waves-effect waves-light mr-3"
                                  onClick={() => setTransactionModal(true)}
                                >
                                  <i className="ri-add-line align-middle mr-2"></i>
                                  Add Transaction
                                </Button>
                              )}
                            </Col>
                          </Row>

                          {!transactionList.length ?
                            <Card className='mt-3'>
                              <CardBody>
                                <CardHeader style={{ textAlign: 'center' }}>
                                  No Transactions Found
                                </CardHeader>
                              </CardBody>
                            </Card> : null}

                          <>
                            {transactionList?.length ? <Container>
                              {transactionList?.map(transaction => (
                                <TransactionCard key={transaction._id} transaction={transaction} childDetail={childInfo} refresh={() => fetchTransactions(childInfo?._id)} />
                              ))}
                            </Container> : null}
                          </>
                        </TabPane>
                      )}
                    </>
                  )}



                </TabContent>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment >
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewSingleChild);

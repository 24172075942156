/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import Select from 'react-select';
import { AvForm } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewTransaction, editTransaction } from '../../api/transaction';

const EditTransactionModal = ({
  modalOpenState,
  closeModalHandler,
  childId,
  refresh,
  childinfo,
  data
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [rows, setRows] = useState([
    { description: '', rate: '', quantity: '', price: '' }
  ]);
  const [batchCreateRequestLoading, setBatchCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [paidAmount, setPaidAmount] = useState(0);
  const [address, setAddress] = useState(null);
  const [totalAmountToPay, setTotalAmountToPay] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState();
  const [paidTillNow, setPaidTillNow] = useState(0);

  const description_list = [
    { value: 'Admission Fee', label: 'Admission Fee' },
    { value: 'Tution Fee', label: 'Tution Fee' },
    { value: 'Library', label: 'Library' },
    { value: 'Newspaper', label: 'Newspaper' },
    { value: 'Uniform', label: 'Uniform' },
    { value: 'Others', label: 'Others' }
  ];
  const address_list = [
    {
      value:
        '5, Mahatma Gandhi Rd, opp. Rajubhai Dhoklawala, Amrut Nagar, Kamala Nagar, Dahanukar Wadi',
      label:
        '5, Mahatma Gandhi Rd, opp. Rajubhai Dhoklawala, Amrut Nagar, Kamala Nagar, Dahanukar Wadi, Kandivali West, Mumbai, Maharashtra 400067'
    },
    {
      value: 'Shop 02, Off Link Road, Extension, Mahavir Nagar',
      label:
        'Shop 02, Off Link Road, Extension, Mahavir Nagar, Kandivali West, Mumbai, Maharashtra 400067'
    }
  ];

  useEffect(() => {
    if (data) {
      setRows(
        data.invoiceDetail.map((detail) => ({
          description: { value: detail.description, label: detail.description },
          rate: detail.rate,
          quantity: detail.quantity,
          price: detail.price
        }))
      );
      setAddress({ value: data.address, label: data.address });
      setTotalAmountToPay(data.totalAmountToPay);
      setDiscountAmount(data.discountAmount || 0);
      setPaidTillNow(data.totalAmountToPay - data.dueAmount);
      setDiscountType(
        data.discountType && {
          value: data.discountType,
          label: data.discountType
        }
      );
      if (!data.isSessionBatch) {
        setPaidAmount(data.paidAmount);
      }
      setDueAmount(data.dueAmount);
    }
  }, [data]);

  useEffect(() => {
    if (data.isSessionBatch) {
      const totalPaid = rows.reduce(
        (sum, row) => sum + row.rate * row.quantity,
        0
      );
      setPaidAmount(totalPaid);
      // setDueAmount(totalAmountToPay - totalPaid);
    } else {
      // console.log(totalAmountToPay,'totalAmount to pay',paidTillNow,paidAmount)
      // setDueAmount(totalAmountToPay - paidAmount)
    }
  }, [rows, totalAmountToPay, discountAmount]);

  const updatePaidNormalBatch = (totalPaid) => {
    if (parseFloat(discountAmount) > parseFloat(totalPaid)) {
      setPaidAmount(0);
      setDiscountAmount(0);
    } else {
      setDueAmount(
        parseFloat(dueAmount) + parseFloat(paidAmount) - parseFloat(totalPaid)
      );
      setPaidAmount(totalPaid);
      // setDueAmount(totalAmountToPay-totalPaid)
    }
    // setDueAmount(totalAmountToPay - totalPaid)
  };

  const handleTransactionUpdate = async () => {
    resetErrorAlert();

    const form = {
      invoiceDetail: rows,
      paidAmount: paidAmount,
      dueAmount: dueAmount,
      rows: JSON.stringify(rows),
      discountAmount: discountAmount,
      address: address.value,
      discountType: discountType?.value ? discountType.value : ''
    };

    rows.forEach((dt) => {
      dt.description = dt.description.label;
    });

    setBatchCreateRequestLoading(true);
    try {
      const { response, error } = await editTransaction(form, data?._id);
      if (error) {
        showErrorAlert(error);
        return;
      }
      showSuccessAlert(response.message);
      resetForm();
      // refresh();
      closeModalHandler();
    } catch (error) {
      showErrorAlert('Error updating transaction ', error);
      console.log(error);
    } finally {
      setBatchCreateRequestLoading(false);
    }
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    if (field === 'rate' || field === 'quantity') {
      updatedRows[index].price =
        updatedRows[index].rate * updatedRows[index].quantity;
    }
    setRows(updatedRows);
  };

  const addRow = () => {
    const newRows = [
      ...rows,
      { description: '', rate: '', quantity: '', price: '' }
    ];
    setRows(newRows);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const showErrorAlert = (message) => {
    setRequestError({ message, show_alert: true });
  };

  const resetErrorAlert = () => {
    setRequestError({ message: '', show_alert: false });
  };

  const showSuccessAlert = (message) => {
    // console.log("messages i got:: ",message)
    setSuccessAlert({ message, show_alert: true });
  };

  const resetSuccessAlert = () => {
    setSuccessAlert({ message: '', show_alert: false });
  };

  const resetForm = () => {
    setRequestError({ show_alert: false, message: '' });
    setRows([{ description: '', rate: '', quantity: '', price: '' }]);
    setBatchCreateRequestLoading(false);
    // setSuccessAlert({ message: '', show_alert: false });
    setAddress(null);
    setTotalAmountToPay(0);
    setDueAmount(0);
    setDiscountAmount(0);
  };

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert
          success
          title="Updated"
          onConfirm={() => {
            resetSuccessAlert();
            refresh();
          }}
        >
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Edit Transaction</ModalHeader>
        <ModalBody>
          {requestError.show_alert && (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          )}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleTransactionUpdate}
          >
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Batch</Label>
                  <Input
                    value={data?.batchId?.name}
                    disabled
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Batch Type</Label>
                  <Input
                    value={
                      data?.batchId
                        ? data?.batchId?.isSessionBatch
                          ? 'Session Batch'
                          : 'Normal Batch'
                        : ''
                    }
                    disabled
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    {data?.batchId?.isSessionBatch
                      ? 'Session Fee'
                      : 'Batch Fee'}
                  </Label>

                  <Input
                    type="number"
                    value={
                      data?.batchId?.isSessionBatch
                        ? data?.batchId?.sessionFee
                        : totalAmountToPay
                    }
                    readOnly
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              {data?.batchId?.isSessionBatch && (
                <Col md="4">
                  <FormGroup>
                    <Label>Registered Sessions</Label>

                    <Input
                      type="number"
                      value={
                        data?.batchId?.isSessionBatch &&
                        data?.batchId?.totalSessions
                      }
                      readOnly
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              )}

              <Col md="4">
                <FormGroup>
                  <Label>Total Fee</Label>
                  <Input
                    type="number"
                    value={totalAmountToPay}
                    readOnly
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Amount Paid till now</Label>
                  <Input
                    type="number"
                    value={paidTillNow}
                    readOnly
                    className="form-control"
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Address</Label>
                  <Select
                    value={address}
                    options={address_list}
                    onChange={(e) =>
                      setAddress({ value: e.value, label: e.label })
                    }
                  />
                </FormGroup>
              </Col>
              {/* <Col md="3">
                                <FormGroup>
                                    <Label>Due Amount</Label>
                                    <Input
                                        type="number"
                                        value={dueAmount}
                                        readOnly
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col> */}
              {/* <Col md="3">
                                <FormGroup>
                                    <Label>No of Sessions</Label>
                                    <Input
                                        value={data?.noOfSessions || ''}
                                        disabled
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col> */}
              {/* <Col md="3">
                                <FormGroup>
                                    <Label>Discount Amount</Label>
                                    <Input
                                        type="number"
                                        value={discountAmount}
                                        onChange={(e) => setDiscountAmount(Number(e.target.value))}
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col> */}
            </Row>

            <hr style={{ borderTop: '1px solid lightgray' }} />
            {rows.map((row, index) => (
              <Row key={index}>
                <Col md="5">
                  <FormGroup>
                    <Label>Description</Label>
                    <Select
                      value={row.description}
                      options={description_list}
                      onChange={(e) =>
                        handleRowChange(index, 'description', {
                          value: e.value,
                          label: e.label
                        })
                      }
                      disabled={!data.isSessionBatch}
                    />
                    {/* {!data.isSessionBatch &&
                      row.description.value === 'Others' && (
                        <Input
                          type="text"
                          value={row.customDescription || ''}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              'customDescription',
                              e.target.value
                            )
                          }
                          placeholder="Enter description"
                          className="mt-2"
                        />
                      )} */}
                  </FormGroup>
                </Col>
                <Col md="2.5">
                  <FormGroup>
                    <Label>Rate</Label>
                    <Input
                      type="number"
                      value={row.rate}
                      onChange={(e) =>
                        handleRowChange(index, 'rate', Number(e.target.value))
                      }
                      className="form-control"
                      disabled={!data.isSessionBatch}
                    />
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup>
                    <Label>Quantity</Label>
                    <Input
                      type="number"
                      value={row.quantity}
                      onChange={(e) =>
                        handleRowChange(
                          index,
                          'quantity',
                          Number(e.target.value)
                        )
                      }
                      className="form-control"
                      disabled={!data.isSessionBatch}
                    />
                  </FormGroup>
                </Col>
                <Col md="2.5">
                  <FormGroup>
                    <Label>Price</Label>
                    <Input
                      type="number"
                      value={row.price}
                      readOnly
                      className="form-control"
                      disabled={!data.isSessionBatch}
                    />
                  </FormGroup>
                </Col>
                <Col md="1" className="d-flex align-items-center">
                  {data.isSessionBatch && (
                    <>
                      <Button color="success" onClick={addRow}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 7V4.5a.5.5 0 0 1 1 0V7h2.5a.5.5 0 0 1 0 1H9v2.5a.5.5 0 0 1-1 0V8H5.5a.5.5 0 0 1 0-1H8z" />
                        </svg>
                      </Button>
                      {index > 0 && (
                        <Button
                          color="danger"
                          onClick={() => removeRow(index)}
                          className="ml-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-dash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5z" />
                          </svg>
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            ))}

            <hr style={{ borderTop: '1px solid lightgray' }} />

            {/* Adding discount and total paid amount  */}

            <Row>
              <Col md="5"></Col>
              <Col md="3" className="mx-1"></Col>
              <Col md="2.5" className="mx-2">
                <FormGroup>
                  <Label for="paid-fee">Amount Before Discount</Label>
                  <Input
                    id="paid-fee"
                    type="number"
                    value={paidAmount}
                    readOnly={data?.batchId?.isSessionBatch}
                    onChange={(e) => updatePaidNormalBatch(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="1"></Col>
            </Row>

            <Row>
              <Col md="5"></Col>
              <Col md="3" className="mx-1">
                <FormGroup>
                  <Label for="discount">Discount Type</Label>
                  <Select
                    value={discountType}
                    options={[
                      { value: 'Promotional', label: 'Promotional' },
                      { value: 'Additional', label: 'Additional' }
                    ]}
                    onChange={(e) =>
                      setDiscountType({ value: e.value, label: e.label })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="2.5" className="mx-2">
                <FormGroup>
                  <Label for="discount">Discount</Label>
                  <Input
                    id="discount"
                    type="number"
                    value={discountAmount}
                    onChange={(e) => {
                      if (parseFloat(e.target.value) > parseFloat(paidAmount)) {
                        showErrorAlert('Discount cannot be more than paid fee');
                        setDiscountAmount(0);
                      } else {
                        setDiscountAmount(e.target.value || 0);
                      }
                    }}
                    min={0}
                  />
                </FormGroup>
              </Col>
              <Col md="1"></Col>
            </Row>

            <Row>
              <Col md="5"></Col>
              <Col md="3" className="mx-1">
                <FormGroup>
                  <Label for="due-fee">Due Amount</Label>
                  <Input
                    id="due-fee"
                    type="number"
                    value={dueAmount}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="2.5" className="mx-2">
                <FormGroup>
                  <Label for="paid-fee">Paid Amount</Label>
                  <Input
                    id="paid-fee"
                    type="number"
                    value={paidAmount - discountAmount}
                    readOnly={data?.isSessionBatch}
                    onChange={(e) => updatePaidNormalBatch(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="1"></Col>
            </Row>

            <ModalFooter>
              <Button
                type="submit"
                color="primary"
                disabled={batchCreateRequestLoading}
              >
                {batchCreateRequestLoading
                  ? 'Updating...'
                  : 'Update Transaction'}
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditTransactionModal;

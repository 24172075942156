/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input,
  InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import PageDataLoader from '../Utility/PageDataLoader';
import { getExecutionInfo, updateExecution } from '../../api/execution';
import FormUpload from '../../components/FormUpload';
import { isValidYouTubeLink } from '../../helpers/expression-checker';

const UpdateExecutionModal = ({
  modalOpenState,
  closeModalHandler,
  executionId,
  fetchNewData,
  authUser,
  date,
  history = false,
  reload,
  setReload
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [form, setForm] = useState(new FormData());
  const [images, setImages] = useState([]);
  const [updateExecutionRequestLoading, setUpdateExecutionRequestLoading] =
    useState(false);
  const [executionInfo, setExecutionInfo] = useState({});
  const [executionInfoLoading, setExecutionInfoLoading] = useState(true);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [ytLinks, setYTLinks] = useState([]);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [isPresent, setIsPresent] = useState(false);


  const handleInputChange = (e) => {
    setYoutubeLink(e.target.value);
  };

  const handleButtonClick = (link) => {
    resetErrorAlert();
    if (!isValidYouTubeLink(link)) {
      setRequestError({ show_alert: true, message: "Enter valid youtube link!" })
      return;
    }

    const trimmedLink = link.trim();
    if (trimmedLink !== '') {
      setYTLinks(prev => [...prev, trimmedLink]);
      setYoutubeLink('')
    }
  };

  const handleRemoveLink = (linkToRemove) => {
    setYTLinks(prevYtLinks => prevYtLinks.filter(link => link !== linkToRemove));
  };

  async function handleUpdateExecution(event, values) {
    resetErrorAlert();
    form.set('note', values.note);
    form.set('isPresent', isPresent);
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`images`, g));
    }
    if (Array.isArray(ytLinks) && ytLinks.length > 0) {
      ytLinks.map((g, index) => form.append(`links`, g));
    }
    setUpdateExecutionRequestLoading(true);
    const { response, error } = await updateExecution(executionId, form);
    setUpdateExecutionRequestLoading(false);
    setForm(new FormData());
    setImages([]);
    if (history) {
      showSuccessAlert(response.message);
      // closeModalHandler();
      setReload(!reload)

    }
    else {
      if (date)
        fetchNewData(1, 3, false, 'date$desc', [], date, date);
      else fetchNewData();
      setYoutubeLink('')
      setYTLinks([])
      if (error) {
        showErrorAlert(error);
        closeModalHandler();
      } else {
        showSuccessAlert(response.message);
        closeModalHandler();
      }
    }
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    setForm(new FormData());
    setImages([]);
    if (authUser && executionId) {
      fetchExecutionInfo();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  useEffect(() => {
    if (executionInfo) {
      setIsPresent(executionInfo.isPresent);
    }
  }, [authUser, executionInfo, modalOpenState]);

  const fetchExecutionInfo = async () => {
    setExecutionInfoLoading(true);
    const response = await getExecutionInfo(executionId);

    if (response) {
      setExecutionInfo(response);
      setExecutionInfoLoading(false);
    } else {
      setExecutionInfoLoading(false);
      showErrorAlert('Execution with given ID not found');
      return;
    }
  };

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {'Update Execution'}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError.show_alert && (
              <SweetAlert error title={requestError.message} onConfirm={resetErrorAlert}>
                {requestError.message}
              </SweetAlert>
            )}
            {executionInfoLoading ? (
              <div className="d-flex justify-content-center">
                <PageDataLoader />
              </div>
            ) : (
              <AvForm
                className="needs-validation"
                onValidSubmit={handleUpdateExecution}
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Note</Label>
                      <AvField
                        name="note"
                        placeholder="Enter Note ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Note is required'
                          }
                        }}
                        defaultValue={executionInfo?.note}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Attendance</Label>

                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr"
                      >
                        <Input
                          id={'customSwitch' + executionId}
                          type="checkbox"
                          className="custom-control-input p-3"
                          defaultChecked={isPresent}
                        />
                        <Label
                          htmlFor={'customSwitch' + executionId}
                          className="custom-control-label"
                          onClick={(e) => {
                            setIsPresent(!isPresent);
                          }}
                        >
                          Attendance Status
                        </Label>
                      </div>
                    </FormGroup>

                  </Col>
                  <Col md="12">
                    <FormUpload
                      setImages={setImages}
                      title="Execution Images"
                      video={true}
                    />
                    <InputGroup style={{ marginTop: '5px' }}>
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <i className="fab fa-youtube"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Enter YouTube link"
                        value={youtubeLink}
                        onChange={handleInputChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={() => handleButtonClick(youtubeLink)}>
                          +
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {ytLinks?.length > 0 && (
                      <div className="mt-3">
                        {ytLinks.map(link => (
                          <p key={link} className="link-item" style={{ display: 'flex', alignItems: 'center' }}>
                            &#128279; {/* Unicode for link icon */}
                            <a href={link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{link}</a>
                            <span
                              onClick={() => handleRemoveLink(link)}
                              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                            >
                              &#10060; {/* Unicode for cross icon */}
                            </span>
                          </p>
                        ))}
                      </div>
                    )}
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={updateExecutionRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {updateExecutionRequestLoading
                      ? 'Updating ...'
                      : 'Update Execution'}
                  </Button>
                </ModalFooter>
              </AvForm>
            )}
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateExecutionModal);

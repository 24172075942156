import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { deleteRestoreExecution } from '../../api/execution';

const DeleteExecutionModal = ({
  modalOpenState,
  closeModalHandler,
  executionId,
  type,
  fetchNewData,
  date,
  reload,
  setReload,
  history = false
}) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }
  const deleteExecution = async () => {
    resetErrorAlert();
    setRequestLoading(true);

    const { response, error } = await deleteRestoreExecution(executionId, type);
    if (response) {
      setRequestLoading(false);
      setSuccessAlert(
        `Execution ${type === 'restore' ? 'restored' : 'deleted'} successfully`
      );
      closeModalHandler();
      if (history) {
        setReload(!reload)

      }
      else {
        if (date) fetchNewData(1, 3, false, 'date$desc', [], date, date);
        else fetchNewData();
      }
      if (error) {
        setRequestLoading(false);
        showErrorAlert(error);
        return;
      }
    }
  };

  useEffect(() => {
    resetErrorAlert();
  }, [modalOpenState]);
  return (
    <React.Fragment>
      {successAlert && (
        <SweetAlert
          success
          title={type === 'restore' ? 'Restored' : 'Deleted'}
          onConfirm={() => setSuccessAlert(false)}
        >
          {successAlert}
        </SweetAlert>
      )}

      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}></ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <h4>{`Are you sure you want to ${type} this execution?`}</h4>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={requestLoading}
            type="button"
            color="light"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="danger"
            onClick={() => deleteExecution()}
          >
            {requestLoading
              ? type === 'restore'
                ? 'Restoring ...'
                : 'Deleting...'
              : type === 'restore'
                ? 'Restore'
                : 'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteExecutionModal;

/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalFooter,
  Input,
  Container,
  CardBody,
  Card,
  InputGroup, InputGroupAddon, InputGroupText,
  Badge,
  CardFooter,
  CardHeader,
  Collapse,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getBatchExecutionChildrenList } from '../../api/batch';
import PageDataLoader from '../Utility/PageDataLoader';
import { addNewBatchExecution, bulkExecutionPublish, fetchExecutionHistory, updateExecution } from '../../api/execution';
import FormUpload from '../../components/FormUpload';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isValidYouTubeLink } from '../../helpers/expression-checker';
import { getExecutionList } from '../../api/execution';
import { validatePermissionKey } from '../../helpers/permission';
import {
  DELETE_EXECUTION,
  UPDATE_EXECUTION,
  VIEW_FEEDBACK_RESPONSE
} from '../Utility/PermissionKeys';
import ExecutionCarousel from '../Child/ExecutionCarousel';
import { getChildInfo } from '../../api/child';
import { getAllTransactionsOfChild } from '../../api/transaction';
import Paginator from '../Utility/Paginator';
import UpdateExecutionModal from '../Child/UpdateExecutionModal';
import DeleteExecutionModal from '../Child/DeleteExecutionModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AddBatchExecution = (props) => {
  const { batchId } = useParams()
  console.log(props, 'props')

  const [date, setDate] = useState(
    new Date() >= new Date(props.location.state.batchStartDate) &&
      new Date() <= new Date(props.location.state.batchEndDate)
      ? new Date()
      : new Date(props.location.state.batchStartDate)
  );


  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [loading, setLoading] = useState(false);


  const [childOptions, setChildOptions] = useState([]);
  const [childOptionsLoading, setChildOptionsLoading] = useState(false);

  const [addExecutionModalVisible, setAddExecutionModalVisible] = useState(false);
  const [executionHistory, setExecutionHistory] = useState([])
  const [reload, setReload] = useState(false)

  const openAddBatchExecutionModal = () => {
    setAddExecutionModalVisible(true);
  };

  const closeAddBatchExecutionModal = () => {
    setAddExecutionModalVisible(false);
  }

  const [breadcrumbItems] = useState([
    { title: 'Batch', link: '/batches' },
    { title: 'Add Batch Execution', link: '#' }
  ]);

  const fetchBatchExecutionHistory = async (date, batch) => {
    setLoading(true)
    const { response, error } = await fetchExecutionHistory(moment(new Date(date)).format(
      'YYYY-MM-DD'
    ), batchId);
    if (error) {
      showErrorAlert("Error in fetching executions")
      setLoading(false)
    }
    else {
      setExecutionHistory(response.data)
      setLoading(false)


    }
  }


  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');

    // eslint-disable-next-line
  }, [props.authUser, listElem, linkElem]);

  useEffect(() => {
    // calling use effeect
    console.log("hello", date, batchId)
    fetchBatchExecutionHistory(date, batchId)
    fetchChildOptions(date)
  }, [date, reload]);

  const fetchChildOptions = async (
    selectedDate,
    current_page = 1,
    page_limit = 100
  ) => {
    setChildOptionsLoading(true);
    const response = await getBatchExecutionChildrenList(
      props.match.params.batchId,
      selectedDate,
      current_page,
      page_limit
    );
    if (response) {
      setChildOptions(buildOptions(response?.data || []));
      setChildOptionsLoading(false);
    } else {
      setChildOptionsLoading(false);

      return;
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  async function bulkPublishExecutions() {
    setLoading(true);
    const { response, error } = await bulkExecutionPublish(date)
    if (error) {
      showErrorAlert("Error in bulk publishing")
      setLoading(false);

    }
    else {
      showSuccessAlert("All drafted executions published");
      setLoading(false);
      setReload(!reload)

    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        {openAddBatchExecutionModal && (
          <>
            <AddBatchExecutionModal addExecutionModalVisible={addExecutionModalVisible} setLoading={setLoading} close={closeAddBatchExecutionModal} date={date} showSuccessAlert={showSuccessAlert} setSuccessAlert={setSuccessAlert} childOptions={childOptions} setChildOptions={setChildOptions} batchId={batchId} reload={reload} setReload={setReload} />
          </>
        )}
        {successAlert.show_alert && (
          <SweetAlert
            success
            title="Created"
            onConfirm={resetSuccessAlert}
          >
            {successAlert.message}
          </SweetAlert>
        )}
        {requestError.show_alert && (
          <SweetAlert error title={requestError.message} onConfirm={resetErrorAlert}>
            {requestError.message}
          </SweetAlert>
        )}
        {loading ? (
          <div className="d-flex justify-content-center">
            <PageDataLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title={props.location.state.batchName}
              breadcrumbItems={breadcrumbItems}
            />
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                >
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Date</Label>
                        <AvField
                          name="date"
                          placeholder="Date ..."
                          type="date"
                          min={
                            props.location.state.batchStartDate &&
                            props.location.state.batchStartDate.split('T')[0]
                          }
                          max={
                            props.location.state.batchEndDate &&
                            props.location.state.batchEndDate.split('T')[0]
                          }
                          className="form-control"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Date is required'
                            }
                          }}
                          onChange={async (e) => {
                            setDate(e.target.value);
                            // await fetchChildOptions(e.target.value);
                          }}
                          defaultValue={moment(new Date(date)).format(
                            'YYYY-MM-DD'
                          )}
                          helpMessage="Date should lie between batch start and end date"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" className="text-right" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <div style={{ display: "flex", gap: 10 }}>
                        <Button
                          color="success"
                          onClick={bulkPublishExecutions}
                          disabled={executionHistory.length === 0}
                        >
                          Bulk Publish Executions
                        </Button>
                        <Button
                          color="primary"
                          onClick={openAddBatchExecutionModal}
                        >
                          Add Execution
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    {executionHistory.map((data, i) => {

                      return (<Col md="6">
                        <ChildrenAccordian child={data} key={i} authUser={props.authUser} date={date} reload={reload} setReload={setReload} showSuccessAlert={showSuccessAlert} showErrorAlert={showErrorAlert} />

                      </Col>
                      )

                    })}

                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
    </React.Fragment >
  );
};

function ChildrenAccordian({ child, date, authUser, reload, setReload, showErrorAlert, showSuccessAlert }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(_ => !_);
  };





  return (
    <Card className="mb-3">
      <div
        style={{ cursor: 'pointer' }}
        className="text-dark"
      >
        <CardBody>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              toggleOpen();
            }}>
            <h5>              {child.child.name}
            </h5>
          </div>
          <Collapse isOpen={isOpen}>
            <ChildsExecution key={child._id} data={child} authUser={authUser} date={date} reload={reload} setReload={setReload} showErrorAlert={showErrorAlert} showSuccessAlert={showSuccessAlert} />
          </Collapse>
        </CardBody>
      </div>
    </Card>
  );
}

function ChildsExecution(props) {
  const [executionInfo, setExecutionInfo] = useState(props.data);
  const [executionFilterModal, setExecutionFilterModal] = useState(false);
  const [executionId, setExecutionId] = useState('');
  const [feedbackInfo, setFeedbackInfo] = useState(null);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [updateExecutionModalOpen, setUpdateExecutionModalOpen] = useState(false);
  const [executionData, setExecutionData] = useState('');
  const [deleteExecutionModalOpen, setDeleteExecutionModalOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    message: '',
    show_alert: false,
    error: false,
    title: ""
  });


  function openUpdateExecutionModal(execution) {
    setExecutionId(execution);
    setUpdateExecutionModalOpen(true);
  }

  function closeUpdateExecutionModal() {
    setUpdateExecutionModalOpen(false);
  }

  async function openDeleteExecutionModal(executionId, requestType) {
    setExecutionData({
      executionId,
      requestType
    });
    setDeleteExecutionModalOpen(true);
  }

  function closeDeleteExecutionModal() {
    setDeleteExecutionModalOpen(false);
  }


  async function handlePublishExecutions() {

    const formData = new FormData()
    formData.set("draft", false)
    const { response, error } = await updateExecution(props.data._id, formData)
    console.log(response, error)
    if (error) {
      props.showErrorAlert("Error in publishing execution")

    } else {

      props.showSuccessAlert("Execution published successfully")
      props.setReload(!props.reload);

    }
  }


  return (
    <div>
      {executionInfo && (
        <UpdateExecutionModal
          modalOpenState={updateExecutionModalOpen}
          closeModalHandler={closeUpdateExecutionModal}
          executionId={executionId}
          // fetchNewData={fetchExecutionInfo}
          authUser={props.authUser}
          date={props.date}
          reload={props.reload}
          setReload={props.setReload}
          history={true}
        />
      )}
      {executionData && (
        <DeleteExecutionModal
          modalOpenState={deleteExecutionModalOpen}
          closeModalHandler={closeDeleteExecutionModal}
          executionId={executionData.executionId}
          type={executionData.requestType}
          // fetchNewData={fetchExecutionInfo}
          reload={props.reload}
          setReload={props.setReload}
          history={true}

          date={props.date}
        />
      )}

      <>
        {errorAlert.show_alert && (
          <SweetAlert
            success
            title={errorAlert.title}
            onConfirm={() => {
              setErrorAlert({
                show_alert: false, message: ''
              })
            }}
          >
            {errorAlert.message}
          </SweetAlert>
        )}
        {errorAlert.show_alert && (
          <SweetAlert error={errorAlert.error} title={errorAlert.message} onConfirm={() => {
            setErrorAlert({
              show_alert: false, message: ''
            })
          }}>
            {errorAlert.message}
          </SweetAlert>
        )}

        <Row >
          <Col sm={12}>
            <CardHeader>
              <Row className='mt-1'>
                <Col sm={2}>
                  <Badge
                    className="mr-1 mb-2"
                    style={{
                      padding: '10px 20px',
                      fontSize: '14px',
                      background:
                        executionInfo.batch.offering.color
                    }}
                    pill
                  >
                    {executionInfo.batch.offering.name}
                  </Badge>

                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  {executionInfo.draft ? <Button
                    size="sm"
                    color="primary"
                    className=" waves-effect waves-light mr-2 mb-2"
                    onClick={handlePublishExecutions}
                  >
                    <i className="fas fa-eye mr-2"></i>
                    Publish executions
                  </Button> : (
                    <Button
                      size='sm'
                      className=" waves-effect waves-light mr-2 mb-2"
                      color='success'
                      disabled
                    >
                      Published

                    </Button>
                  )}

                  {validatePermissionKey(
                    props.authUser,
                    UPDATE_EXECUTION
                  ) && (
                      <Button
                        size="sm"
                        color="warning"
                        className=" waves-effect waves-light mr-2 mb-2"
                        onClick={() =>
                          openUpdateExecutionModal(
                            executionInfo._id
                          )
                        }
                      >
                        <i className="fas fa-edit mr-2"></i>
                        Edit
                      </Button>
                    )}
                  {validatePermissionKey(
                    props.authUser,
                    DELETE_EXECUTION
                  ) && (
                      <Button
                        size="sm"
                        color="danger"
                        className=" waves-effect waves-light mb-2"
                        onClick={() => {
                          openDeleteExecutionModal(
                            executionInfo._id,
                            executionInfo.isDeleted
                              ? 'restore'
                              : 'delete'
                          );
                        }}
                      >
                        {executionInfo.isDeleted ? (
                          <i className="fas fa-trash-restore mr-2"></i>
                        ) : (
                          <i className="fas fa-trash mr-2"></i>
                        )}
                        {executionInfo.isDeleted
                          ? 'Restore'
                          : 'Delete'}
                      </Button>
                    )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row >

              </Row>
              {/* <Row className="mb-1">
               
              </Row> */}

              <Row>
                <Col>
                  <h5>Note: </h5>
                  {executionInfo.note}

                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <Badge
                    className="mr-2 mb-2"
                    color={
                      executionInfo.isPresent
                        ? 'success'
                        : 'danger'
                    }
                    style={{
                      padding: '10px 20px',
                      fontSize: '14px'
                    }}
                    pill
                  >
                    {executionInfo.isPresent
                      ? 'Present'
                      : 'Absent'}
                  </Badge>
                </Col>
              </Row>
              {/* <Row> */}
              {executionInfo.images.length !== 0 && (
                <ExecutionCarousel executionID={executionInfo._id} images={executionInfo.images}
                  reload={props.reload}
                  setReload={props.setReload}
                  history={true}
                // fetchChildInfo={fetchChildInfo}

                // fetchExecutionInfo={fetchExecutionInfo}


                />
              )}
              {/* </Row> */}

            </CardBody>
            <CardFooter className="text-muted" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              {moment(new Date(executionInfo.date)).format(
                'DD-MM-YYYY'
              )}

            </CardFooter>
          </Col>
        </Row>
      </>

    </div >
  );
}

function AddBatchExecutionModal(props) {
  const [executionArray, setExecutionArray] = useState([
    {
      batch: props.batchId,
      child: '',
      note: '',
      isPresent: true,
      images: [],
      links: []
    }
  ]);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [images, setImages] = useState([]);
  const [addExecutionRequestLoading, setAddExecutionRequestLoading] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [ytLinks, setYTLinks] = useState('');

  const handleInputChange = (e) => {
    setYoutubeLink(e.target.value);
  };


  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  const handleButtonClick = (index, link) => {
    resetErrorAlert();
    if (!isValidYouTubeLink(link)) {
      setRequestError({ show_alert: true, message: "Enter valid youtube link!" })
      return;
    }

    const trimmedLink = link.trim();
    if (trimmedLink !== '') {
      setExecutionArray(prevExecutionArray => {
        const newExecutionArray = [...prevExecutionArray];
        newExecutionArray[index]['links'] = [...newExecutionArray[index]['links'], link]
        return newExecutionArray;
      });
      setYoutubeLink('')
    }
  };


  const handleRemoveLink = (index, linkToRemove) => {
    setExecutionArray(prevExecutionArray => {
      const newExecutionArray = [...prevExecutionArray];

      // Filter out the link that matches the linkToRemove
      newExecutionArray[index]['links'] = newExecutionArray[index]['links'].filter(link => link !== linkToRemove);

      return newExecutionArray;
    });
  };

  async function handleAddExecution(event, values) {
    // props.setLoading(true);
    // Submit the form
    resetErrorAlert();

    if (images && images.length > 0) {
      let newExecutionArray = [...executionArray];
      newExecutionArray[newExecutionArray.length - 1].images = images;
      setExecutionArray(newExecutionArray);
    }

    let nonMarked = executionArray.filter((k) => k.isPresent === null)
    if (nonMarked.length > 0) {
      showErrorAlert("Please mark attendance for child " + nonMarked[0].child.label)
      return
    }
    setAddExecutionRequestLoading(true)

    let success = true

    if (executionArray) {
      for (const e of executionArray) {
        let form = new FormData();
        form.set('batch', e.batch);
        form.set('date', props.date);
        form.set('child', e.child.value);
        form.set('note', e.note);
        form.set('isPresent', e.isPresent);
        form.set('links', e.links);
        form.set('draft', true);
        if (e.images.length > 0) {
          e.images.forEach((g) => form.append('images', g));
        }
        let response = await addNewBatchExecution(form);
        if (response.error) {
          setRequestError({ message: 'Error in adding execution', show_alert: true });
          success = false;
          break;
        }
      }
    }

    if (success) {
      setAddExecutionRequestLoading(false);
      setExecutionArray([
        {
          batch: props.batchId,
          child: '',
          note: '',
          isPresent: null,
          images: [],
          links: []
        }
      ]);
      props.showSuccessAlert('Execution added successfully');
      props.close();

    }
    setExecutionArray([
      {
        batch: props.batchId,
        child: '',
        note: '',
        isPresent: true,
        images: [],
        links: []
      }
    ]);
    // props.reloadExecutionHistory(props.date);
    // props.setLoading(false);
    props.setReload(!props.reload)
  }

  async function handlePublishExecution(event, values) {
    // props.setLoading(true);
    // Submit the form
    resetErrorAlert();

    if (images && images.length > 0) {
      let newExecutionArray = [...executionArray];
      newExecutionArray[newExecutionArray.length - 1].images = images;
      setExecutionArray(newExecutionArray);
    }

    let nonMarked = executionArray.filter((k) => k.isPresent === null)
    if (nonMarked.length > 0) {
      showErrorAlert("Please mark attendance for child " + nonMarked[0].child.label)
      return
    }
    setAddExecutionRequestLoading(true)

    let success = true

    if (executionArray) {
      for (const e of executionArray) {
        let form = new FormData();
        form.set('batch', e.batch);
        form.set('date', props.date);
        form.set('child', e.child.value);
        form.set('note', e.note);
        form.set('isPresent', e.isPresent);
        form.set('links', e.links);
        form.set('draft', false);
        if (e.images.length > 0) {
          e.images.forEach((g) => form.append('images', g));
        }
        let response = await addNewBatchExecution(form);
        if (response.error) {
          setRequestError({ message: 'Error in adding execution', show_alert: true });
          success = false;
          break;
        }
      }
    }
    if (success) {
      setAddExecutionRequestLoading(false);
      props.showSuccessAlert('Execution added successfully');
      props.close();
    }
    setExecutionArray([
      {
        batch: props.batchId,
        child: '',
        note: '',
        isPresent: true,
        images: [],
        links: []
      }
    ]);
    props.setReload(!props.reload)

    // props.reloadExecutionHistory(props.date);
  }

  function handleChildSelection(index, selectedItem) {
    let newChildOptions = props.childOptions.filter((c) => c !== selectedItem);
    props.setChildOptions(newChildOptions);
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['child'] = selectedItem;
    setExecutionArray(newExecutionArray);
  }

  function handleNote(index, e) {
    resetErrorAlert();
    let value = e.target.value;
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['note'] = value;
    setExecutionArray(newExecutionArray);
  }

  function handleIsPresent(index, value) {
    resetErrorAlert();
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['isPresent'] =
      value;
    setExecutionArray(newExecutionArray);
  }

  let removeExecutionFields = (i) => {
    let newExecutionArray = [...executionArray];
    if (newExecutionArray[i]['child']) {
      let newChildOptions = props.childOptions;
      newChildOptions.push(newExecutionArray[i]['child']);
      props.setChildOptions(newChildOptions);
    }
    newExecutionArray.splice(i, 1);

    setExecutionArray(newExecutionArray);
  };

  function addExecutionFields() {
    let newExecutionArray = [...executionArray];
    newExecutionArray.push({
      batch: props.batchId,
      child: '',
      note: '',
      isPresent: true,
      images: [],
      links: []
    });
    setExecutionArray(newExecutionArray);
  };

  return (
    <Modal isOpen={props.addExecutionModalVisible} backdrop="static" size="xl">
      <ModalHeader>
        Add Execution
      </ModalHeader>
      <ModalBody>
        {props.date &&
          executionArray.map((element, index) => (
            <AvForm className="needs-validation" style={{ padding: "5px" }}>
              <Row>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>Child</Label>
                        <Select
                          options={props.childOptions}
                          disabled={props.childOptionsLoading}
                          onChange={(e) =>
                            handleChildSelection(index, e)
                          }
                          value={element.child}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <Label>Note</Label>
                        <AvField
                          name="note"
                          placeholder="Enter Note ..."
                          type="textarea"
                          rows="12"
                          className="form-control"
                          onChange={(e) => handleNote(index, e)}
                          value={element.note}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label>Attendance</Label>
                        <Select
                          options={[{ "value": false, label: "Absent" }, { "value": true, label: "Present" }]}
                          onChange={(e) => {
                            handleIsPresent(index, e.value)
                          }}
                          defaultValue={{ "value": true, label: "Present" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="10">
                      <FormUpload
                        setImages={setImages}
                        title="Execution Images"
                        video={true}
                      />
                      <InputGroup style={{ marginTop: '5px' }}>
                        <InputGroupAddon addonType='append'>
                          <InputGroupText>
                            <i className="fab fa-youtube"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Enter YouTube link"
                          value={youtubeLink}
                          onChange={handleInputChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="primary" onClick={() => handleButtonClick(index, youtubeLink)}>
                            +
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                      {executionArray[index]['links']?.length > 0 && (
                        <div className="mt-3">
                          {executionArray[index]['links'].map(link => (
                            <p key={link} className="link-item" style={{ display: 'flex', alignItems: 'center' }}>
                              &#128279; {/* Unicode for link icon */}
                              <a href={link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{link}</a>
                              <span
                                onClick={() => handleRemoveLink(index, link)}
                                style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                              >
                                &#10060; {/* Unicode for cross icon */}
                              </span>
                            </p>
                          ))}
                        </div>
                      )}

                    </Col>
                    <Col
                      md="2"
                      className="d-flex mt-1  justify-content-start align-items-center"
                    >
                      <Button
                        size="sm"
                        color="danger"
                        disabled={index === 0}
                        className=" waves-effect waves-light"
                        onClick={() => removeExecutionFields(index)}
                      >
                        <i className="ri-delete-bin-6-line align-middle"></i>
                      </Button>
                    </Col>
                  </Row>

                </Col>

              </Row>
            </AvForm>
          ))}
        <Row className="mt-2 mb-2">
          <Col className="d-flex justify-content-start align-items-center">
            <Button
              size="sm"
              color="info"
              className=" waves-effect waves-light"
              disabled={
                !executionArray[executionArray.length - 1].child ||
                executionArray[executionArray.length - 1].note === ''
              }
              onClick={() => addExecutionFields()}
            >
              <i className="ri-add-line align-middle mr-2"></i>
              Add child
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={addExecutionRequestLoading}
          type="button"
          color="light"
          onClick={() => props.close()}
        >
          Close
        </Button>
        <Button
          type="submit"
          color="success"
          onClick={handleAddExecution}
          disabled={
            !executionArray[executionArray.length - 1].child ||
            executionArray[executionArray.length - 1].note ===
            '' || addExecutionRequestLoading
          }
        >
          {addExecutionRequestLoading
            ? 'Adding ...'
            : 'Add Execution'}
        </Button>
        <Button
          type="submit"
          color="success"
          onClick={handlePublishExecution}
          disabled={
            !executionArray[executionArray.length - 1].child ||
            executionArray[executionArray.length - 1].note ===
            '' || addExecutionRequestLoading
          }
        >
          {addExecutionRequestLoading
            ? 'Publishing ...'
            : 'Publish Execution'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddBatchExecution);
